import React from 'react'
import './CancelModal.css'
import MainButton from '../../MainButton/MainButton';

const CancelModal = (props: any) => {

    return (
        <div className='cancelModal-container'>
            <span 
                className="material-symbols-rounded" 
                style={{ fontSize: 24, marginLeft: 'auto', cursor: 'pointer' }}
                onClick={() => props.cancel()}
            >
                close
            </span>
            <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 46 }}>error</span>
            <h6 className='cancelModal-container-title bold'>¿Querés abandonar el modo de edición?</h6>
            <b className='cancelModal-container-text regular large'>Los cambios no serán guardados.</b>
            <div className='cancelModal-container-botones'>
                <MainButton
                    text='CANCELAR'
                    inverted
                    onPress={() => props.cancel()}
                />
                <MainButton
                    text='ACEPTAR'
                    onPress={() => props.aceptar()}
                />
            </div>
        </div>
    );

}

export default CancelModal;