import React, { useEffect, useState } from 'react'
import './GenericItem.css'

const GenericItem = (props: any) => {

    return (
        <div 
            onClick={() => {
                if(!props.onlyOne) props.setItemSelected(props.data)
            }}
            className='genericItem-container-item'
            style={{ 
                backgroundColor: props.itemSelected?.label == props.label ? 'var(--primary500)' : 'var(--primary200)',
                cursor: props.onlyOne ? 'unset' : 'pointer'
            }}
        >
            <p 
                className='genericItem-container-item-label regular small'
                style={{ color: props.itemSelected?.label == props.label ? 'var(--white)' : 'var(--gray700)' }}
            >{props.label?.toUpperCase()}</p>
        </div>
    );

}

export default GenericItem;