import React, { useEffect, useState } from 'react'
import './MailComponent.css'
import { actionsReadContact } from '../../../actions';

const MailComponent = (props: any) => {

    const [data, setData] = useState<any>(null)

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    useEffect(() => {
        if(props.data) {
            handleParse();
        }
    }, [props.data])

    const toggleViewedMesagge = () => {
        actionsReadContact(data.id)
    }

    return (
        <div 
            className='mailComponent-text'
            style={{ 
                color: data?.leido === 'true' ? 'var(--successPrimary)' : 'var(--primary500)',
                cursor: data?.leido === 'true' ? 'auto' : 'pointer'
            }}
            onClick={() => data?.leido === 'true' ? {} : toggleViewedMesagge()}
        >
            <span className="material-symbols-rounded">{data?.icon}</span>
            {data?.leido === 'false' && <span className="mailComponent-text-tooltiptext">Marcar como leído</span>}
        </div>
    );
}

export default MailComponent;