import React, { useEffect } from 'react'
import './MainButton.css'
import GoogleLogin from '../../assets/images/svgs/googleLogin.svg';
import { ReactSVG } from 'react-svg'




const MainButton = (props: any) => {

    const getClassName = () => {
        if(props.sinFondo) {
            return props.disabled ? 'mainButton-containerDisSinFondo' : 'mainButton-containerSinFondo'
        } else if (props.google) {
            return props.disabled ? 'mainButton-containerDisGoogle' : 'mainButton-containerGoogle'
        } else if (props.inverted) {
            return props.disabled ? 'mainButton-containerDisInverted' : 'mainButton-containerInverted'
        } 
        return (props.disabled===true) ? 'mainButton-containerDis' : 'mainButton-container'
    }

    if(props.google) {
        return (
            <div 
                id={props.id}
                className={getClassName()}
                onClick={() => props.disabled ? {} : props.onPress()}
            >
                <ReactSVG src={GoogleLogin}/>
                <p>
                    &nbsp;&nbsp;&nbsp;{props.text}
                </p>
            </div>
        )
    } 
    
    return (
        <p 
            id={props.id}
            className={getClassName()}
            onClick={() => props.disabled ? {} : props.onPress()}
        >{props.text}</p>
    );

}

export default MainButton;