import React, { useEffect, useState } from 'react'
import './ComprasCard.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'
import GenericMetricCard from '../GenericMetricCard/GenericMetricCard';

const ComprasCard = (props: any) => {

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }

    return (
        <div className='comprasCard-container'>
            {props.data[3] && <p className='comprasCard-container-title bold large'>{props.data[3]}</p>}
            <div className='comprasCard-container-list'>
                {JSON.parse(props.data[6])?.map((a: any, index: any) => (
                    <GenericMetricCard data={a} key={index}/>
                ))}
            </div>
        </div>
    );

}

export default ComprasCard;