import React, { useEffect, useState, useRef } from 'react'
import LeftArrow from '../../assets/images/svgs/leftArrow.svg';
import './Selector.css'
import { ReactSVG } from 'react-svg'

const Selector = (props: any) => {

    const [sortOpen, setSortOpen] = useState<boolean>(false)
    const [currentSort, setCurrentSort] = useState<any>(null)

    const mainRef: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                setSortOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        setCurrentSort(props.default)
    }, [props.default])

    return (
        <div ref={mainRef} className='selector-container'>
            <div 
                className={sortOpen ? 'selector-container-labels' : 'selector-container-labelsClose'}
                onClick={() => setSortOpen(!sortOpen)}
            >
                <b className='selector-container-labels-text regular medium'>&nbsp;&nbsp;{currentSort}&nbsp;&nbsp;&nbsp;</b>
                <ReactSVG
                    src={LeftArrow}
                    style={{ 
                        transform: !sortOpen ? 'rotate(270deg)' : 'rotate(90deg)', 
                        transition: 'transform 150ms ease' 
                    }}
                />
            </div>
            <div 
                className='selector-container-list'
                style={{ display: sortOpen ? 'flex' : 'none'}}
            >
                {props.sortList?.map((a: any, index: number) => (
                    <div 
                        className={currentSort === a.label ? 'selector-container-list-itemSelected' : 'selector-container-list-item'}
                        style={{
                            borderBottomRightRadius: index === props.sortList.length -1 ? 15 : 0,
                            borderBottomLeftRadius: index === props.sortList.length -1 ? 15 : 0,
                        }}
                        onClick={() => {
                            setSortOpen(false)
                            setCurrentSort(a.label)
                            props.itemSelected(a)
                        }}
                        key={index}
                    >
                        <p className='selector-container-list-item-text regular medium' >{a.label}</p>
                        <ReactSVG
                            src={LeftArrow}
                            style={{ 
                                transform: !sortOpen ? 'rotate(270deg)' : 'rotate(90deg)', 
                                transition: 'transform 150ms ease' ,
                                opacity: 0
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );

}

export default Selector;