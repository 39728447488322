import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

const AuthRoute = (props: any) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userAccessToken')

    useEffect(() => {
        props.setUserAccessToken(userToken)
    }, [])

    return (userToken ? <Outlet/> : <Navigate to='/' />)
};
  
export default AuthRoute;