import React, { useEffect, useState, useRef } from 'react'
import './SelectorInput.css'

const SelectorInput = (props: any) => {

    const [sortOpen, setSortOpen] = useState<boolean>(false)

    const mainRef: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                setSortOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        if(props.disabled) setSortOpen(false)
    }, [props.disabled])

    return (
        <div ref={mainRef} className='selectorInput-container'>
            <div 
                className='selectorInput-container-label'
                onClick={() => {
                    if(props.disabled) {}
                    else setSortOpen(!sortOpen)
                }}
                style={{
                    backgroundColor: props.disabled ? 'transparent' : 'var(--white)',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    borderBottomLeftRadius: sortOpen ? 0 : 15,
                    borderBottomRightRadius: sortOpen ? 0 : 15,
                    border: props.disabled ? '1px solid var(--gray200)' : '1px solid var(--primary500)',
                    cursor: props.disabled ? 'auto' : 'pointer'
                }}
            >
                <b 
                    className='selectorInput-container-labels-text regular medium'
                    style={{ color: props.disabled ? 'var(--gray700)' : 'var(--primary500)'}}
                >&nbsp;&nbsp;{props.value ? props.value : props.placeholder}&nbsp;&nbsp;&nbsp;</b>
                {!props.disabled && <span 
                    className="material-symbols-rounded" 
                    style={{ 
                        color: 'var(--primary500)',
                        fontSize: 20,
                        transform: !sortOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
                        transition: 'transform 150ms ease',
                        marginRight: 4,
                        userSelect: 'none'
                    }}
                >expand_more</span>}
            </div>
            <div 
                className='selectorInput-container-list'
                style={{ display: sortOpen ? 'flex' : 'none'}}
            >
                {props.sortList?.map((a: any, index: number) => (
                    <div 
                        className='selectorInput-container-list-item'
                        style={{
                            borderBottomRightRadius: index === props.sortList?.length -1 ? 15 : 0,
                            borderBottomLeftRadius: index === props.sortList?.length -1 ? 15 : 0,
                        }}
                        onClick={() => {
                            setSortOpen(false)
                            props.itemSelected(a)
                        }}
                        key={index}
                    >
                        <p className='selectorInput-container-list-item-text regular medium' >{a.branchName ? a.branchName : a.descripcion}</p>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default SelectorInput;