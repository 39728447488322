import React from 'react'
import './EstadoPagoListModal.css'
import { estadoPagoList } from '../../../util/EstadoPagoListColors';


const EstadoPagoListModal = (props: any) => {

    return (
        <div className='estadoPagoListModal-container'>
            <div className='estadoPagoListModal-container-top'>
                <b className='estadoPagoListModal-container-top-text bold medium'>Tipos de estado de pago</b>
                <div onClick={() => props.onDismiss()} className='estadoPagoListModal-container-top-close'>
                    <span className="material-symbols-rounded">close</span>
                </div>
            </div>
            <div className='estadoPagoListModal-container-list'>
                {estadoPagoList?.map((a: any) => (
                    <div className='estadoPagoListModal-container-list-item'>
                        <b 
                            className='estadoPagoListModal-container-list-item-title regular small'
                            style={{ color: a.color, borderColor: a.color }}
                        >
                            {a.title}
                        </b>
                        <b className='estadoPagoListModal-container-list-item-text regular small'>{a.text}</b>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default EstadoPagoListModal;