import React, { useState } from 'react'
import './MenuItem.css'

const MenuItem = (props) => {

    return (
        <div className='menuItem-container'>
            <p
                className={props.selected ? 'menuItem-container-iconTextSelected' : !props.data.enable ? 'menuItem-container-iconTextDis' : 'menuItem-container-iconText'}
                onClick={() => props.data.enable && !props.selected ? props.onPresss() : {}}
                style={{ opacity: props.data.enable ? 1 : 0.4 }}
            >
                <span className="material-symbols-rounded">{props.data.icon}</span>
                <p>&nbsp;&nbsp;{props.data.name}</p>
            </p>
            {props.data.comingSoon && <p className='menuItem-container-muyPronto'>MUY PRONTO</p>}
        </div>
    );

}

export default MenuItem;