import React, { useEffect, useState } from 'react'
import './TextWithBorderColor.css'

const TextWithBorderColor = (props: any) => {

    const [data, setData] = useState<any>(null)

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    useEffect(() => {
        handleParse();
    }, [props.data])

    return (
        <b 
            className='textWithBorderColor-text medium regular' 
            style={{
                color: data?.color ? data?.color : 'var(--gray700)',
                borderColor: data?.color ? data?.color : 'var(--gray700)',
                borderWidth: 1,
                borderStyle: 'solid'
            }}
        >
            {data?.h1}
        </b>
    );
}

export default TextWithBorderColor;