import React from 'react'
import './GoogleMapModal.css'
import GoogleMapReact from 'google-map-react';

const GoogleMapModal = (props) => {

    return (
        <div className='googleMapModal-container'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDH19rVbhD4pIfNCp70KBxfWUjVJFONdGY" }}
                defaultCenter={{
                    lat: props.lat,
                    lng: props.lng
                }}
                defaultZoom={16}
                options={{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                }}
            > 
                <div
                    className='googleMapModal-container-marker'
                    lat={props.lat}
                    lng={props.lng}
                >
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.979 9.413 6.587C9.021 6.19567 8.55 6 8 6C7.45 6 6.97933 6.19567 6.588 6.587C6.196 6.979 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7873 12.525 12.262C13.5083 10.7373 14 9.38333 14 8.2C14 6.38333 13.4207 4.89567 12.262 3.737C11.104 2.579 9.68333 2 8 2C6.31667 2 4.89567 2.579 3.737 3.737C2.579 4.89567 2 6.38333 2 8.2C2 9.38333 2.49167 10.7373 3.475 12.262C4.45833 13.7873 5.96667 15.4833 8 17.35ZM8 19.625C7.86667 19.625 7.73333 19.6 7.6 19.55C7.46667 19.5 7.35 19.4333 7.25 19.35C4.81667 17.2 3 15.2043 1.8 13.363C0.6 11.521 0 9.8 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.8 15.4 11.521 14.2 13.363C13 15.2043 11.1833 17.2 8.75 19.35C8.65 19.4333 8.53333 19.5 8.4 19.55C8.26667 19.6 8.13333 19.625 8 19.625Z" fill="var(--primary500)"/>
                    </svg>
                </div>
            </GoogleMapReact>
        </div>
    );
}

export default GoogleMapModal;