import React, { useState } from "react";
import "./GlobalWhatsapp.css";
import Modal from 'react-modal';
import WhatsappModal from "../../../Modals/WhatsappModal/WhatsappModal";

const GlobalWhatsapp = (props: any) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [whatsappAlert, setWhatsappAlert] = useState<boolean>(false);

  const whatsappModal = () => {
    return (
      <Modal
        isOpen={whatsappAlert}
        onRequestClose={() => setWhatsappAlert(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '25px',
            width: '30%',
            padding: '20px'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10
          }
        }}
      >
        <WhatsappModal
          onDismiss={() => setWhatsappAlert(false)}
        />   
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {loading ? 
        <div className="globalWhatsapp-loader">
          <span className="globalWhatsapp-loader-loader"></span>
        </div>
        :
        <div className="globalWhatsapp">
          <div 
            className="globalWhatsapp-img"
            onClick={() => setWhatsappAlert(true)}
          >
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.44754 23.068L1.90744 24.2589L3.11926 19.8324L2.83405 19.3785C1.63327 17.4686 0.999071 15.2612 1 12.9946C1.00263 6.38078 6.38389 1 13.0006 1C16.2046 1.00111 19.2165 2.25042 21.4813 4.51785C23.7461 6.78527 24.9926 9.79906 24.9914 13.0043C24.9887 19.6187 19.6075 25 12.9959 25H12.991C10.8385 24.9992 8.72732 24.4209 6.88557 23.3279L6.44754 23.068Z" stroke="var(--primary500)"/>
              <path d="M20.2685 16.4369C20.1783 16.2865 19.938 16.1962 19.5774 16.0157C19.2167 15.8352 17.4438 14.9629 17.1132 14.8424C16.7827 14.7221 16.5422 14.662 16.3018 15.0229C16.0615 15.3839 15.3704 16.1962 15.16 16.4369C14.9496 16.6775 14.7392 16.7077 14.3788 16.5272C14.0182 16.3467 12.8562 15.9659 11.4787 14.7373C10.4067 13.781 9.68297 12.6002 9.47261 12.2391C9.26225 11.8781 9.45019 11.683 9.63072 11.5032C9.79296 11.3416 9.99136 11.0819 10.1717 10.8714C10.3519 10.6609 10.412 10.5103 10.5323 10.2698C10.6525 10.0291 10.5924 9.81855 10.5022 9.63803C10.412 9.45752 9.69086 7.68249 9.39035 6.96042C9.09761 6.25724 8.80033 6.3525 8.57899 6.34139C8.36888 6.33083 8.12821 6.32861 7.88783 6.32861C7.64744 6.32861 7.25673 6.41887 6.92619 6.7799C6.59565 7.14094 5.66406 8.01339 5.66406 9.78828C5.66406 11.5633 6.95623 13.2781 7.13655 13.5187C7.31684 13.7595 9.67939 17.4018 13.2969 18.9639C14.1573 19.3355 14.8289 19.5573 15.3527 19.7235C16.2166 19.998 17.0028 19.9593 17.624 19.8664C18.3169 19.7629 19.7576 18.9941 20.0582 18.1518C20.3586 17.3093 20.3586 16.5873 20.2685 16.4369Z" fill="var(--primary500)"/>
            </svg>
          </div>
          <span className="tooltip">{props.tooltip}</span>
        </div>
      }
      {whatsappModal()}
    </React.Fragment>
  )
};

export default GlobalWhatsapp;
