import axios from './instanceActions'
import { 
    netDashboard,
    netDashboardList,
    netDashboardTrafico,
    netDashboardDetalle,
    netDashboardCompras
} from "../net/Connector";

export const actionDashboard = (setDashboardData: any) => {
    axios.get(netDashboard('principal'))
        .then((response) => {
            setDashboardData(response.data[0])
        })
        .catch((error) => {
        })
};

export const actionDashboardList = (setList: any, data: any, setLoading: any) => {
    setLoading(true)
    axios({
        url: netDashboardList(),
        method: 'post',
        data: data
    })
        .then((response) => {
            setLoading(false)
            setList(response.data)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const actionDashboardTrafico = (setTraficoData: any, selectedFilter: any, setTraficoLoading: any) => {
    setTraficoLoading(true)
    axios.get(netDashboardTrafico(selectedFilter))
        .then((response) => {
            setTraficoData(response.data)
            setTraficoLoading(false)
        })
        .catch((error) => {
            setTraficoLoading(false)
        })
};

export const actionDashboardDetalle = (setDetalleData: any, selectedFilter: any, item: any, setDetalleLoading: any) => {
    setDetalleLoading(true)
    axios.get(netDashboardDetalle(selectedFilter, item))
        .then((response) => {
            setDetalleData(response.data)
            setDetalleLoading(false)
        })
        .catch((error) => {
            setDetalleLoading(false)
        })
};

export const actionDashboardCompras = (setComprasData: any, selectedFilter: any, setComprasLoading: any) => {
    setComprasLoading(true)
    axios.get(netDashboardCompras(selectedFilter))
        .then((response) => {
            setComprasData(response.data)
            setComprasLoading(false)
        })
        .catch((error) => {
            setComprasLoading(false)
        })
};