import React, { useEffect, useState } from 'react';
import './UploadComponent.css'
import MainButton from '../MainButton/MainButton';
import { actionsUploadFile } from '../../actions';

const UploadComponent = (props: any) => {
  const [file, setFile] = useState<any>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<any>({ success: false, text: '' });

  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragging(true)
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    if(file) actionsUploadFile(file, props.type, 'auxPrefijo', setUploading, setUploadSuccess)
  }, [file])

  useEffect(() => {
    if(uploadSuccess?.text !== '') {
        setTimeout(() => {
            setUploadSuccess({ success: false, text: '' })
        }, 3000)
    }
  }, [uploadSuccess])

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className="uploadComponent-container"
      style={{ border: dragging ? '2px dashed var(--primary500)' : '2px dashed var(--gray200)' }}
    >
      <span className="material-symbols-rounded" style={{ fontSize: 56, color: 'var(--gray300)' }}>upload_file</span>
      <p className="uploadComponent-container-text regular" >Arrastrar y soltar o seleccionar un archivo</p>
      <label htmlFor="file-upload" className="uploadComponent-container-label" id='SELECCIONAR ARCHIVO'>
        <div className="uploadComponent-container-label-look">
          <b className="uploadComponent-container-label-look-text bold medium">SELECCIONAR ARCHIVO</b>
        </div>
        <input
          type="file"
          id="file-upload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </label>
    </div>
  );
}

export default UploadComponent;
