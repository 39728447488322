import React, { useState } from 'react'
import './Login.css'
import cards from '../../assets/images/Cards3D.png';
import EmailStep from './EmailStep/EmailStep';
import PasswordStep from './PasswordStep/PasswordStep';

const Login = (props: any) => {

    const [loginProcessData, setLoginProcessData] = useState({step: 'user'})
    const [rememberAccount, setRememberAccount] = useState(false)

    const stepMethods: any = {
        'user': {
            name: "stepMethods.user",
            methodComponent: EmailStep,
        },
        "password": {
            name: "stepMethods.password",
            methodComponent: PasswordStep,
        },
    }
    
    const StepComponent: any = stepMethods[loginProcessData?.step].methodComponent;

    return (
        <div className='login-container'>
            <StepComponent 
                loginProcessData={loginProcessData} 
                setLoginProcessData={setLoginProcessData}
                rememberAccount={rememberAccount}
                setRememberAccount={setRememberAccount}
                setUserData={props.setUserData}
            />
            <div className='login-container-cardsContainer'>
                <img className='login-container-cardsContainer-img' src={cards} />
            </div>
        </div>
    );

}

export default Login;