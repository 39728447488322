import React, { useState } from 'react'
import './GenericModal.css'
import MainButton from '../MainButton/MainButton';
import Exit from '../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'

const GenericModal = (props) => {

    return (
        <div className='genericModal-container'>
            <ReactSVG 
                src={Exit}
                style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                onClick={() => props.onDismiss()}
            />
            <div style={{color: 'var(--primary500)'}}>
                <span className="material-symbols-rounded" style={{ fontSize: 40 }}>{props.icon}</span>
            </div>
            <p className='genericModal-container-title'>{props.title}</p>
            <div className='genericModal-container-buttonWrapper'>
                <MainButton
                    text={props.buttonText}
                    onPress={() => props.onPress()}
                />
            </div>
            <div className='genericModal-container-buttonWrapper'>
                <MainButton
                    text='VOLVER'
                    sinFondo
                    onPress={() => props.onDismiss()}
                />
            </div>
        </div>
    );

}

export default GenericModal;