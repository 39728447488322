import React, { useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Sellers.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import Modal from 'react-modal';
import CrearVendedorModal from '../../components/Modals/CrearVendedorModal/CrearVendedorModal';
import { useLocation } from 'react-router-dom';
import EditarVendedorModal from '../../components/Modals/EditarVendedorModal/EditarVendedorModal';
import TableHeader from '../../components/TableHeader/TableHeader';

const Sellers = (props: any) => {

    const [createModal, setCreateModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [editData, setEditData] = useState<any>(null)
    const location = useLocation();

    const EditSellerAlert = () => {
        return (
            <Modal
                isOpen={editModal}
                onRequestClose={() => setEditModal(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '50%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EditarVendedorModal
                    onDismiss={() => setEditModal(false)}
                    userData={props.userData}
                    editData={editData}
                />   
            </Modal>
        )
    }

    const CreateSellerAlert = () => {
        return (
            <Modal
                isOpen={createModal}
                onRequestClose={() => {
                    setCreateModal(false)
                    window.location.reload();
                }}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '50%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <CrearVendedorModal
                    onDismiss={() => setCreateModal(false)}
                    userData={props.userData}
                />   
            </Modal>
        )
    }

    return (
        <div className='sellers-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='sellers-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    data={{
                        type: 'sellers',
                        search: 'v2/sellers',
                        createTitle: 'CREAR VENDEDOR',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        createPath: '/sellers/create'
                    }}
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'sellers:download' }).length > 0}
                    canCreateCustom={props.userData?.privileges?.filter(function(e: any) { return e === 'sellers:create' }).length > 0}
                    createTableItem={() => setCreateModal(true)}
                    canEditCustom={props.userData?.privileges?.filter(function(e: any) { return e === 'sellers:edit' }).length > 0}
                    editTableItem={() => setEditModal(true)}
                    canDelete={props.userData?.privileges?.filter(function(e: any) { return e === 'sellers:delete' }).length > 0}
                    setEditData={setEditData}
                    deleteData={{
                        title: '¿Confirmás que deseas eliminar<br/>el siguiente vendedor?',
                        subTitleSuccess: 'El vendedor ha sido eliminado exitosamente.',
                        data: "JSON.parse(data[2])?.h1 + '<br/>DNI ' + data[3] + '<br/>' + data[4]"
                    }}
                />
            </div>
            {CreateSellerAlert()}
            {EditSellerAlert()}
        </div>
    );
}

export default Sellers;