import React from 'react'
import './TableBox.css'

const TableBox = (props: any) => {

    return (
        <div className='tableBox-container'>
            <div 
                className='tableBox-container-box'
                style={{
                    backgroundColor: props.isSelected ? 'var(--primary500)' : 'transparent',
                    border: props.isSelected ? '1.6px solid var(--primary500)' : ''
                }}
            >
                {props.isSelected && <span className="material-symbols-rounded" style={{ color: 'var(--white)', fontSize: 15 }}>done</span>}
            </div>
            <span className="tableBox-container-tooltip">Seleccionar</span>
        </div>
    );
}

export default TableBox;