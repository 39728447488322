import React, { useEffect, useState } from 'react'
import './TextWithColor.css'

const TextWithColor = (props: any) => {

    const [data, setData] = useState<any>(null)

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    useEffect(() => {
        handleParse();
    }, [props.data])

    return (
        <div className='textWithColor-container'>
            {data?.icon && 
                <div className="textWithColor-container-tooltip">
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray300)' }}>{data?.icon}</span>
                    <span className="textWithColor-container-tooltiptext">Vendedor en vía pública</span>
                </div>
            }
            <b className='textWithColor-text regular medium' style={{color: data?.color ? data?.color : 'var(--gray700)'}}>{data?.h1}</b>
        </div>
    );
}

export default TextWithColor;