import React, { useEffect } from 'react';
import './PasswordRecoveryFinish.css';
import cards from '../../../../assets/images/Cards3D.png';
import Lottie from 'react-lottie';
import sentSuccess from '../../../../assets/lotties/sentSuccess.json'
import { useNavigate } from 'react-router-dom';

const PasswordRecoveryFinish = (props) => {

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 5000)
  }, [])

  return (
    <div className='passwordRecoveryFinish-container'>
      <div className='passwordRecoveryFinish-container-main'>
        <Lottie
          options={{
              loop: true,
              autoplay: true,
              animationData: sentSuccess,
              rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
              }
          }}
          height={80}
          width={80}
          isStopped={false}
          isPaused={false}
        />
        <p className='passwordRecoveryFinish-container-main-title'>¡Listo!</p>
        <p className='passwordRecoveryFinish-container-main-subTitle'>Cambiaste tu contraseña.<br/>Te redireccionaremos para que puedas iniciar sesión</p>
      </div>
      <div className='passwordRecoveryFinish-container-cardsContainer'>
        <img className='passwordRecoveryFinish-container-cardsContainer-img' src={cards} />
      </div>
    </div>
  );
}

export default PasswordRecoveryFinish;