import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { changeLogo } from '../data';

const resetPrimaryColors = () => {
    const cookies = new Cookies();
    const pp = cookies.get('--grandSonPrincipalPrimario')
    const pp1 = cookies.get('--grandSonPrincipalPVariante1')
    const pp2 = cookies.get('--grandSonPrincipalPVariante2')
    const cp = cookies.get('--grandSonComPrincipal')
    const gsl = cookies.get('grandSonLogo')
    if (pp) {
      document.documentElement.style.setProperty('--primary500', '#' + pp);
    }
    if (pp1) {
      document.documentElement.style.setProperty('--primary400', '#' + pp1);
    }
    if (pp2) {
      document.documentElement.style.setProperty('--primary700', '#' + pp2);
    }
    if (cp) {
      document.documentElement.style.setProperty('--primary200', '#' + cp);
    }
    if (gsl) {
      changeLogo(gsl)
    }
}

const ColorRoute = () => {
    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== '/') {
            resetPrimaryColors()
        }
    }, [])

    return <Outlet/>
};
  
export default ColorRoute;