import React, { useState } from "react";
import "./GlobalDelete.css";
import deleteImg from '../../../../assets/images/svgs/deleteImg.svg'
import { actionsDeleteTableRow } from "../../../../actions";
import Modal from 'react-modal';
import DeleteRowModal from "../../../Modals/DeleteRowModal/DeleteRowModal";

const GlobalDelete = (props: any) => {

  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const DeleteRowAlert = () => {
    return (
        <Modal
            isOpen={deleteAlert}
            onRequestClose={() => setDeleteAlert(false)}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '25px',
                    width: '30%',
                    padding: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 10
                }
            }}
        >
            <DeleteRowModal
                title={props.selectedList?.length === 1 ? props.deleteData?.title : props.deleteMultipleData?.title}
                subTitleSuccess={props.selectedList?.length === 1 ? props.deleteData?.subTitleSuccess : props.deleteMultipleData?.subTitleSuccess}
                onDismiss={() => setDeleteAlert(false)}
                data={getDataForOneEntry()}
            />   
        </Modal>
    )
  }

  const getDataForOneEntry = () => {
    if (props.selectedList?.length === 1) {
      const data = getDataEntryById(props.selectedList[0].toString());
      return data[5] + '<br/>' + data[4]
    } else return null;
  }

  function getDataEntryById(id: any) {
    // Find the index of the entry with the given ID
    const index = props.dataRows.findIndex((entry: any) => entry[1] === id);
  
    // If the ID is found, return the entire data entry
    if (index !== -1) {
      return props.dataRows[index];
    } else {
      return null; // Return null if the ID is not found
    }
  }

  return (
    <React.Fragment>
      {loading ? 
        <div className="globalDelete-loader">
          <span className="globalDelete-loader-loader"></span>
        </div>
        :
        <div className="globalDelete">
          <img 
            className="globalDelete-img" 
            src={deleteImg}
            onClick={() => {
              const dataSend = {
                'ids': props.selectedList
              }
              setDeleteAlert(true)
              //actionsDeleteTableRow(dataSend, setLoading)
            }}
          />
          <span className="tooltip">{props.tooltip}</span>
          {DeleteRowAlert()}
        </div>
      }
    </React.Fragment>
  )
};

export default GlobalDelete;
