import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment/moment";

const DateBetweenComponent = (props) => {
  const [localFecha, setLocalFecha] = useState(() => {
    const storedValue = sessionStorage.getItem(props.data?.text);
    return storedValue === null ? "" : JSON.parse(storedValue);
  });
  const [inputFocus, setInputFocus] = useState(false);
  const [fechaAplied, setFechaAplied] = useState(null);

  const validDates = () => {
    return Moment(localFecha, "YYYY-MM-DD", true).isValid();
  };

  function replaceValue(obj, keyToReplace, replacementText) {
    if (obj instanceof Object) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'string') {
            obj[key] = obj[key].replace(new RegExp(keyToReplace, 'g'), replacementText);
          } else if (obj[key] instanceof Object) {
            replaceValue(obj[key], keyToReplace, replacementText);
          }
        }
      }
    }
    return obj;
  }

  const updateObject = (originalObject, inputValue) => {
    const updatedObject = { ...originalObject };
    // Loop through the originalObject and its nested properties
    const newFilter = replaceValue(
      updatedObject,
      fechaAplied ? fechaAplied : '\\$VALOR',
      inputValue
    );
    setFechaAplied(inputValue);

    return newFilter;
  };

  useEffect(() => {
    if (validDates() && props.data.text==='Desde') {
      const fecha = Moment(localFecha, "YYYY-MM-DD").format("DD/MM/YYYY")
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(fecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .startOf("day")
            .toISOString()
        ),
        props.data.text
      );
    }
    if (validDates() && props.data.text==='Hasta') {
      const fecha = Moment(localFecha, "YYYY-MM-DD").format("DD/MM/YYYY")
      props.changeValue(
        updateObject(
          props.data?.filter,
          Moment(fecha, "DD/MM/YYYY")
            .utcOffset("+0000")
            .endOf("day")
            .toISOString()
        ),
        props.data.text
      );
    }
  }, [localFecha]);

  return (
    <div className="DateBetweenComponent-container">
      <b className="DateBetweenComponent-container-text regular small">
        {props.data.text}
      </b>
      <div className="DateBetweenComponent-container-inputContainer">
        <DatePicker
          selected={localFecha}
          onChange={(date) => setLocalFecha(date)}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date(2999, 11, 31)}
          placeholderText="DD/MM/YYYY"
          className="DateBetweenComponent-container-inputContainer-input"
          style={{
            border: inputFocus
              ? "1px solid var(--primary500)"
              : validDates(localFecha)
              ? "1px solid var(--gray300)"
              : "1px solid var(--errorPrimary)",
          }}
          customInput={
            <input
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                boxSizing: 'border-box',
                borderRadius: '15px',
                border: '1px solid var(--gray300)',
                background: 'var(--white)',
                padding: '10px',
                width: '100%',
                outline: 'none',
                fontFamily: 'Lato',
                color: 'var(--gray700)',
              }}
              placeholder="DD/MM/YYYY"
            />
          }
        />
      </div>
      {(localFecha && !validDates(localFecha)) && (
        <p className="DateBetweenComponent-container-error regular small">
          Formato inválido.
        </p>
      )}
    </div>
  );
};

export default DateBetweenComponent;
