import React, { useEffect, useState } from 'react'
import { 
    getCheckoutData,
    getPolizaData,
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasItem.css'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';

const PolizasItem = (props: any) => {

    const [itemData, setPolizaData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkoutData, setCheckoutData] = useState<any>(null)

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if(itemData) getCheckoutData(itemData.checkoutId, setCheckoutData, setLoading)
    }, [itemData])

    return (
        <div className='polizasItem-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasItem-container-data'>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            label='CATEGORÍA'
                            value={itemData?.productPolicyDTO?.categoryName}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='TIPO'
                            value={itemData?.productPolicyDTO?.categoryName}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='AÑO'
                            value={itemData?.productPolicyDTO?.model}
                            disabled
                            required
                        />
                    </div>
                    <div className='polizasItem-container-data-double'>
                        <CustomTextInput
                            label='MARCA, MODELO Y VERSIÓN'
                            value={itemData?.productPolicyDTO?.name}
                            disabled
                            required
                        />
                    </div>
                    <div className='polizasItem-container-data-section'>
                        <CustomTextInput
                            label='PATENTE'
                            value={checkoutData?.productInfo?.carplate[0]}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='CHASIS'
                            value={checkoutData?.productInfo?.carchasisnumber[0]}
                            disabled
                            required
                        />
                        <CustomTextInput
                            label='MOTOR'
                            value={checkoutData?.productInfo?.carmotornumber[0]}
                            disabled
                            required
                        />
                    </div>
                    {checkoutData?.productInfo?.frente &&
                        <>
                            <h5 className='polizasItem-container-data-title bold'>Imágenes</h5>
                            <div className='polizasItem-container-data-section'>
                                <CustomTextInput
                                    label='FRENTE'
                                    img={checkoutData?.productInfo?.frente[0]}
                                    value='Frente.jpg'
                                    disabled
                                    required
                                />
                                <CustomTextInput
                                    label='LATERAL CONDUCTOR'
                                    img={checkoutData?.productInfo?.lateral_acompañante[0]}
                                    value='Lateral-conductor.jpg'
                                    disabled
                                    required
                                />
                                <CustomTextInput
                                    label='LATERAL ACOMPAÑANTE'
                                    img={checkoutData?.productInfo?.lateral_conductor[0]}
                                    value='Lateral-acompañante.jpg'
                                    disabled
                                    required
                                />
                                <CustomTextInput
                                    label='PARTE TRASERA'
                                    img={checkoutData?.productInfo?.trasera[0]}
                                    value='Parte-trasera.jpg'
                                    disabled
                                    required
                                />
                            </div>
                        </>
                    }
                </div>
            }
        </div>
    );

}

export default PolizasItem;