import { ReactComponent as Argentina } from './/assets/images/svgs/argentinaLogo.svg';
import { ReactComponent as Mexico } from './assets/images/svgs/mexicoLogo.svg';

export const data = null;

export let sonLogo = 'https://resources.wecover.co/carfacilLogo.png';
export let sonLogoBlanco = 'https://resources.wecover.co/carfacilLogoBlanco.svg';

export let sonLogoSize  = '27%';

export const changeLogo = (url: string) => {
    sonLogo = url;
}

export const countries = [
    {
        id: 1,
        title: "ARGENTINA",
        img: Argentina,
        url: '',
        lang: () => import('./assets/lang/es-AR.json'),
    }
];