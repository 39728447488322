import React, { useState } from 'react';
import './PasswordRecovery.css';
import cards from '../../../../assets/images/Cards3D.png';
import CustomTextInput from '../../../../components/CustomTextInput/CustomTextInput';
import MainButton from '../../../../components/MainButton/MainButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { recoveryPassReciveId } from '../../../../actions';
import { sonLogo } from '../../../../data';
import { FormattedMessage } from 'react-intl';

const PasswordRecovery = (props) => {

  const location = useLocation();
  const [emailText, setEmailText] = useState(location.state?.userName)
  const [inputError, setInputError] = useState(null)
  const navigate = useNavigate()

  const isMail = (e) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(e).toLowerCase())) {
      return false;
    } return true
  }

  return (
    <div className='passwordRecovery-container'>
      <div className='passwordRecovery-container-main'>
        <img className='passwordRecovery-container-main-logo' src={sonLogo} />
        <p className='passwordRecovery-container-main-title'>Recuperar contraseña</p>
        <p className='passwordRecovery-container-main-subTitle'><FormattedMessage id="admin.passwordReset.subTitle" defaultMessage="" /></p>
        <div className='passwordRecovery-container-main-emailWrapper'>
          <CustomTextInput
            label='Email'
            placeholder='nombre@ejemplo.com'
            onChange={(e) => setEmailText(e.target.value)}
            value={emailText}
            error={inputError}
          />
        </div>
        <div className='passwordRecovery-container-main-buttonWrapper'>
          <MainButton
            id='recuperarContraseña'
            disabled={!isMail(emailText)}
            text='RECUPERAR CONTRASEÑA'
            onPress={() => recoveryPassReciveId(emailText, navigate)}
          />
        </div>
        <MainButton
          id='Volver'
          text='Volver'
          onPress={() => navigate(-1)}
          sinFondo
        />
      </div>
      <div className='passwordRecovery-container-cardsContainer'>
        <img className='passwordRecovery-container-cardsContainer-img' src={cards} />
      </div>
    </div>
  );
}

export default PasswordRecovery;