import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

const ProtectedRoute = (props: any) => {
    
    const checkPrivileges = () => {
        if(props.userData?.role?.name === 'USUARIO') {
            const cookies = new Cookies();
            cookies.remove('userAccessToken');
            cookies.remove('rememberAccount');
        } return true
    }

    return (checkPrivileges() ? <Outlet/> : <Navigate to='/'/>)
};
  
export default ProtectedRoute;