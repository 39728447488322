import React, { useState } from 'react'
import './WhatsappModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'

const WhatsappModal = (props: any) => {

    const [text, setText] = useState<string>('')

    return (
        <div className='whatsappModal-container'>
            <ReactSVG 
                src={Exit}
                style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                onClick={() => props.onDismiss()}
            />
            <p>Enviar mensaje por Whatsapp</p>
        </div>
    );

}

export default WhatsappModal;