import React, { useEffect, useRef, useState } from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './CrearCotizaciones.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import GuardarCotizacionModal from '../../components/Modals/GuardarCotizacionModal/GuardarCotizacionModal';
import Notification from '../../components/Notification/Notification';
import { actionDescargarCotizacion, postCotizacion } from '../../actions';
import DescargarCotizacionModal from '../../components/Modals/DescargarCotizacionModal/DescargarCotizacionModal';

const CrearCotizaciones = (props: any) => {

    const navigate = useNavigate();
    const iframeRef: any = useRef();
    const [appBaseIframe] = useState<any>(process.env.REACT_APP_IFRAME); //Base url for Iframe
    const [appBaseIframePath] = useState<any>(process.env.REACT_APP_IFRAME_PATH); //Default path
    const [appBaseIframePathOffer] = useState<any>(process.env.REACT_APP_IFRAME_PATH_OFFER); //path for Ver Cotizacion
    const [appBaseIframePathOpen] = useState<any>(process.env.REACT_APP_IFRAME_PATH_OPEN); //path for Open crear Cotizacion
    const [iframeState, setIframeState] = useState<any>(0);
    const location: any = useLocation();

    //GUARDAR COTIZACION
    const [guardarCotizacionAlert, setGuardarCotizacionAlert] = useState<boolean>(false);
    const [guardarCotizacionLoading, setGuardarCotizacionLoading] = useState<boolean>(false);
    const [offerId, setOfferId] = useState<any>(null);
    const [notification, setNotification] = useState({ success: false, text: '' });
    const [openOpcionList, setOpenOpcionList] = useState<boolean>(false);
    const [descargarCotizacionAlert, setDescargarCotizacionAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const individualId: boolean = location?.state?.individualId;
    const isOpen: boolean = location?.state?.open;

    //PHASES
    const [title, setTitle] = useState<string>('Crear cotización');
    const [canGoBack, setCanGoBack] = useState<boolean>(false);
    const [canSaveQuote, setSaveQuote] = useState<boolean>(false);

    const sendAdminToken = () => {
        if(window.location.hostname == 'localhost') {
            iframeRef.current.contentWindow.postMessage(
                'Token: ' + props.userAccessToken,
                'http://localhost:3001'
            )
        } else {
            iframeRef.current.contentWindow.postMessage(
                'Token: ' + props.userAccessToken,
                appBaseIframe
            )
        }
    }

    const getIframeUrl = () => {
        let path = appBaseIframePath;
        if (individualId) path = appBaseIframePathOffer + individualId + '&admin=True'
        if (isOpen) path = appBaseIframePathOpen
        if(window.location.hostname == 'localhost') {
            return "http://localhost:3001" + path
        } else {
            if (path) {
                return appBaseIframe + path
            } else if (path) {
                return appBaseIframe + path
            } return appBaseIframe
        }
    }

    const recieveMessageFromChild = () => {
        window.addEventListener("message", (event) => {
            if(window.location.hostname == 'localhost') {
                if (event.origin !== "http://localhost:3001") return;
                if (event.data?.startsWith('/')) {
                    // Finding the substring containing '/insurance_policies' and the word after '/'
                    let firstPart = event.data?.split(',')[0]?.trim(); // Extracting '/insurance_policies'
                    // Finding the substring between the curly braces '{}'
                    try {
                        let secondPart = event.data?.match(/\{(.*?)\}/)[1]; // Extracting 'Emisión fallida'
                        if(secondPart) {
                            sessionStorage.setItem(secondPart, "true");
                        }
                    } catch {

                    }
                    navigate(firstPart)
                } if (event.data === 'Recovering') {
                    setTitle('Recuperar cotización')
                } if (event.data === 'Emitting') {
                    setTitle('Emitir cobertura')
                } if (event.data === 'Not-Emitting') {
                    if(isOpen) setTitle('Recuperar cotización')
                    else setTitle('Crear cotización')
                } if (event.data === 'CanGoBack') {
                    setCanGoBack(true)
                } if (event.data === 'Not-CanGoBack') {
                    setCanGoBack(false)
                } if (event.data === 'Give' && props.userAccessToken) {
                    sendAdminToken();

                } if (event.data?.includes('CanSaveQuote') && event.data !== 'Not-CanSaveQuote') {
                    setSaveQuote(true)
                    const regex = /CanSaveQuote:\s*([a-fA-F0-9-]+)/;
                    const match = event.data?.match(regex);
                    setOfferId(match[1])
                } if (event.data === 'Not-CanSaveQuote') {
                    setSaveQuote(false)
                }
            } else {
                if (event.origin !== appBaseIframe) return;
                if (event.data?.startsWith('/')) {
                    // Finding the substring containing '/insurance_policies' and the word after '/'
                    let firstPart = event.data.split(',')[0].trim(); // Extracting '/insurance_policies'
                    // Finding the substring between the curly braces '{}'
                    try {
                        let secondPart = event.data?.match(/\{(.*?)\}/)[1]; // Extracting 'Emisión fallida'
                        if(secondPart) {
                            sessionStorage.setItem(secondPart, "true");
                        }
                    } catch {

                    }
                    navigate(firstPart)
                } if (event.data === 'Recovering') {
                    setTitle('Recuperar cotización')
                } if (event.data === 'Emitting') {
                    setTitle('Emitir cobertura')
                } if (event.data === 'Not-Emitting') {
                    if(isOpen) setTitle('Recuperar cotización')
                    else setTitle('Crear cotización')
                } if (event.data === 'CanGoBack') {
                    setCanGoBack(true)
                } if (event.data === 'Not-CanGoBack') {
                    setCanGoBack(false)
                } if (event.data === 'Give' && props.userAccessToken) {
                    sendAdminToken();
                } if (event.data?.includes('CanSaveQuote') && event.data !== 'Not-CanSaveQuote') {
                    setSaveQuote(true)
                    const regex = /CanSaveQuote:\s*([a-fA-F0-9-]+)/;
                    const match = event.data?.match(regex);
                    setOfferId(match[1])
                } if (event.data === 'Not-CanSaveQuote') {
                    setSaveQuote(false)
                }
            } return;
        }, false);
    }


    useEffect(() => {
        setIframeState(iframeState + 1);
        recieveMessageFromChild();
    }, [props.userAccessToken, individualId])

    useEffect(() => {
        setSaveQuote(false)
    }, [])

    useEffect(() => {
        if(isOpen) setTitle('Recuperar cotización')
    }, [isOpen])

    //MODALS
    const descargarCotizacionModal = () => {
        return (
            <Modal
                isOpen={descargarCotizacionAlert}
                onRequestClose={() => setDescargarCotizacionAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '0px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <DescargarCotizacionModal
                    dismiss={() => setDescargarCotizacionAlert(false)}
                    offerId={offerId}
                    descargar={(selectedPackages: any, insurers: any) => {
                        setDescargarCotizacionAlert(false)
                        actionDescargarCotizacion(selectedPackages, offerId, setLoading, insurers)
                    }}
                />
            </Modal>
        )
    }

    const guardarCotizacionModal = () => {
        return (
            <Modal
                isOpen={guardarCotizacionAlert}
                onRequestClose={() => setGuardarCotizacionAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '32px 16px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <GuardarCotizacionModal
                    dismiss={() => setGuardarCotizacionAlert(false)}
                    guardar={(text: string) => {
                        setGuardarCotizacionAlert(false)
                        postCotizacion(offerId, text, setGuardarCotizacionLoading, setNotification)
                    }}
                />
            </Modal>
        )
    }

    return (
        <div className='crearCotizaciones-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='crearCotizaciones-container-main'>
                <div className='crearCotizaciones-container-main-hedear'>
                    <div className='crearCotizaciones-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='crearCotizaciones-container-main-main'>
                    <div className='crearCotizaciones-container-main-main-top'>
                        <Notification
                            notification={notification}
                            setNotification={setNotification}
                        />
                        <div className='crearCotizaciones-container-main-main-top-left'>
                            <h2 className='crearCotizaciones-container-main-main-top-left-title bold'>{title}</h2>
                            {canGoBack && 
                                <div className='crearCotizaciones-container-main-main-top-left-volver' onClick={() => navigate('/cotizaciones')}>
                                    <span className="material-symbols-rounded" style={{ fontSize: 18, color: 'var(--primary500)'}}>chevron_left</span>
                                    <b className='crearCotizaciones-container-main-main-top-left-volver-text bold medium'>&nbsp;&nbsp;Volver a Cotizaciones</b>
                                </div>
                            }
                        </div>
                        {canSaveQuote && 
                            <div className='crearCotizaciones-container-main-main-top-right'>
                                <b className={guardarCotizacionLoading ? 'crearCotizaciones-container-main-main-top-right-downloadStart bold medium' : 'crearCotizaciones-container-main-main-top-right-download bold medium'} onClick={() => setGuardarCotizacionAlert(true)}>{guardarCotizacionLoading ? 'GUARDANDO...' : 'GUARDAR COTIZACIÓN'}</b>
                                <div className='crearCotizaciones-container-main-main-top-right-opcions'>
                                    {loading ?
                                        <div className='crearCotizaciones-container-main-main-top-right-opcions-loading'>
                                            <span className='crearCotizaciones-container-main-main-top-right-opcions-loading-loader'/>
                                        </div>
                                        :
                                        <div className='crearCotizaciones-container-main-main-top-right-opcions-button' onClick={() => setOpenOpcionList(!openOpcionList)}>
                                            <span className="material-symbols-rounded" style={{ fontSize: 20, color: 'var(--primary500)'}}>more_vert</span>
                                        </div>
                                    }
                                    {openOpcionList &&
                                        <div className='crearCotizaciones-container-main-main-top-right-opcions-list'>
                                            <b 
                                                className='crearCotizaciones-container-main-main-top-right-opcions-list-item regular medium'
                                                onClick={() => {
                                                    setOpenOpcionList(false)
                                                    setDescargarCotizacionAlert(true)
                                                }}
                                            >Descargar cotización</b>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <iframe key={iframeState} title='' src={getIframeUrl()} ref={iframeRef} className='crearCotizaciones-container-main-main-iframe'></iframe>
                </div>
            </div>
            {guardarCotizacionModal()}
            {descargarCotizacionModal()}
        </div>
    );
}

export default CrearCotizaciones;