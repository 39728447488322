import React, { useEffect, useState } from 'react';
import './CSVUploader.css'
import MainButton from '../MainButton/MainButton';
import { actionsUploadFile } from '../../actions';

const CSVUploader = (props: any) => {
  const [file, setFile] = useState<any>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<any>({ success: false, text: '' });

  const handleDrop = (e: any) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragging(true)
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    if(uploadSuccess?.text !== '') {
        setTimeout(() => {
            setUploadSuccess({ success: false, text: '' })
        }, 3000)
    }
  }, [uploadSuccess])

  return (
    <div className="cSVUploader-container">
        <span 
            className="material-symbols-rounded" 
            style={{ 
                color: 'var(--gray700)', 
                fontSize: 20,
                position: 'absolute',
                right: 12,
                top: 12,
                cursor: 'pointer'
            }}
            onClick={() => props.onDismiss()}
        >close</span>
        <p className="cSVUploader-container-title">Subir Archivo</p>
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="cSVUploader-container-dropBox"
        >
            <label htmlFor="file-upload" style={{ cursor: 'pointer', marginBottom: '20px' }}>
                <span style={{ padding: '10px 20px', border: '1px solid #ccc', borderRadius: '5px', background: '#f9f9f9' }}>Upload File</span>
                <input
                    type="file"
                    id="file-upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </label>
            <p>{dragging ? 'Drop it...' : 'Drag & Drop or Select an Excel file'}</p>
            {file &&
                <div>
                    <p>Archivo:</p>
                    <p>{file?.name}</p>
                </div>
            }
        </div>
        <div className="cSVUploader-container-buttonWrapper">
            <MainButton
                text={uploading ? 'ENVIANDO...' : 'ENVIAR'}
                onPress={() => actionsUploadFile(file, props.type, 'auxPrefijo', setUploading, setUploadSuccess)}
                loading={uploading}
                disabled={!file || uploading}
            />
        </div>
        <p>{uploadSuccess?.text}</p>
    </div>
  );
}

export default CSVUploader;
