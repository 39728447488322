import React from 'react'
import './OpcionComponent.css'
import DateBetweenComponent from './DateBetweenComponent/DateBetweenComponent';
import CheckBoxComponent from './CheckBoxComponent/CheckBoxComponent';
import DateBetweenOptionListComponent from './DateBetweenOptionListComponent/DateBetweenOptionListComponent';

const OpcionComponent = (props: any) => {

    const opcionTypeComponentMethods: any = {
        "checkbox": {
            name: "opcionTypeComponentMethods.checkbox",
            methodComponent: CheckBoxComponent,
        },
        "date_between": {
            name: "opcionTypeComponentMethods.date_between",
            methodComponent: DateBetweenComponent,
        },
        "date_between_option_list": {
            name: "opcionTypeComponentMethods.date_between_option_list",
            methodComponent: DateBetweenOptionListComponent,
        }
    }

    const OpcionTypeComponent: any = opcionTypeComponentMethods[props.type].methodComponent;

    return (
        <div className='OpcionComponent-container'>
            <OpcionTypeComponent
                changeValue={(value: any, opcionText: any) => props.opcionUpdated(value, opcionText)}
                data={props.data}
            />
        </div>
    )
}

export default OpcionComponent;