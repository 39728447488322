import React, { useEffect, useState } from 'react'
import { 
    actionGetCobranzaData,
    getPolizaData
} from '../../../actions'
import './PolizasCobranza.css'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import { estadoPagoList } from '../../../util/EstadoPagoListColors'
import EmptyList from '../../EmptyList/EmptyList'

const PolizasCobranza = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [cobranzaData, setCobranzaData] = useState<any>(null)

    useEffect(() => {
        actionGetCobranzaData(props.polizaId, setCobranzaData, setLoading);
    }, [])

    return (
        <div className='polizasCobranza-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasCobranza-container-data'>
                    {cobranzaData?.length > 0 ? 
                        <div className='polizasCobranza-container-data-list'>
                            {cobranzaData?.map((a: any) => (
                                <div className='polizasCobranza-container-data-list-item'>
                                    <div className='polizasCobranza-container-data-list-item-data'>
                                        <b className='polizasCobranza-container-data-list-item-data-date regular medium'>{Moment(a.createdAt).format('DD-MM-YYYY')}</b>
                                        <b 
                                            className='polizasCobranza-container-data-list-item-data-estado regular medium'
                                            style={{ color: estadoPagoList?.filter((b: any) => (b.title?.toLowerCase() == a.status?.toLowerCase()))[0]?.color}}
                                        >{'Estado: ' + estadoPagoList?.filter((b: any) => (b.title?.toLowerCase() == a.status?.toLowerCase()))[0]?.title}</b>
                                        <div className='polizasCobranza-container-data-list-item-data-details'>
                                            <b className='polizasCobranza-container-data-list-item-data-details-text regular medium'>{'Valor: ' + a.value}</b>
                                            <b className='polizasCobranza-container-data-list-item-data-details-text regular medium'>{'Endoso: ' + a.numeroPoliza + '/' + a.endoso}</b>
                                            <b className='polizasCobranza-container-data-list-item-data-details-text regular medium'>{'Tipo de endoso: ' + a.type}</b>
                                            <b className='polizasCobranza-container-data-list-item-data-details-text regular medium'>{'Vto Aseg: ' + Moment(a.vto).format('DD-MM-YYYY')}</b>
                                        </div>
                                    </div>
                                    <div className='polizasCobranza-container-data-list-item-line'/>
                                </div>
                            ))}
                        </div>
                        :
                        <EmptyList 
                            text='Actualmente la cobertura no tiene cobranzas'
                            svg={(<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM25 46.875C12.9187 46.875 3.125 37.0812 3.125 25C3.125 12.9187 12.9187 3.125 25 3.125C37.0812 3.125 46.875 12.9187 46.875 25C46.875 37.0812 37.0812 46.875 25 46.875Z" fill="#C4C4C4"/>
                            <path d="M17.1875 20.3125C18.9134 20.3125 20.3125 18.9134 20.3125 17.1875C20.3125 15.4616 18.9134 14.0625 17.1875 14.0625C15.4616 14.0625 14.0625 15.4616 14.0625 17.1875C14.0625 18.9134 15.4616 20.3125 17.1875 20.3125Z" fill="#C4C4C4"/>
                            <path d="M32.8125 20.3125C34.5384 20.3125 35.9375 18.9134 35.9375 17.1875C35.9375 15.4616 34.5384 14.0625 32.8125 14.0625C31.0866 14.0625 29.6875 15.4616 29.6875 17.1875C29.6875 18.9134 31.0866 20.3125 32.8125 20.3125Z" fill="#C4C4C4"/>
                            <path d="M35.9375 29.6875H14.0625V32.8125H35.9375V29.6875Z" fill="#C4C4C4"/>
                            </svg>
                            )}
                        />
                    }
                </div>
            }
        </div>
    );

}

export default PolizasCobranza;