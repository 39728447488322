export const genericErrorHandler = (error: any) => {

  let title = 'UPS! Ocurrió un error';

  if (error.message === 'Network Error') {
    title = '¡UPS! Error de conexión';
  } else {
    if (error.response && error.response.data && error.response.data.message) {
      title = error.response.data.message;
    }
  }
  return title
};