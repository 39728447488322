import React from 'react'
import './UserCotizacionesComponent.css'

const UserCotizacionesComponent = (props: any) => {


    return (
        <div className='userCotizacionesComponent-container'>
            <p className='userCotizacionesComponent-container-data regular medium'>{props?.data?.date}</p>
            <p className='userCotizacionesComponent-container-data regular medium'>{props?.data?.name + ' ' + props?.data?.model}</p>
            <p className='userCotizacionesComponent-container-data regular medium'></p>
        </div>
    );

}

export default UserCotizacionesComponent;