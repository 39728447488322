import React, { useEffect, useState } from 'react';
import './UploadingComponent.css'
import { actionsGetFiles } from '../../actions';

const UploadingComponent = (props: any) => {

  const [list, setList] = useState<any>(null);

  useEffect(() => {
    actionsGetFiles(setList);
  }, [])

  useEffect(() => {
    if(list && list?.filter((a: any) => a.status === "PENDING" || a.status === "PROCESSING")?.length > 0) {
      setTimeout(() => {
        actionsGetFiles(setList)
      }, 5000)
    }
  }, [list])

  const getProgressBarClass = (status: string) => {
    switch (status) {
      case "PENDING":
      case "PROCESSING":
        return "uploadingComponent-container-main-list-item-bar-inProgressBar"
      case "ERROR":
        return "uploadingComponent-container-main-list-item-bar-errorBar"
      case "FINISHED":
        return "uploadingComponent-container-main-list-item-bar-completedBar"
      default:
        return "uploadingComponent-container-main-list-item-bar-errorBar"
    }
  }

  return (
    <div className="uploadingComponent-container">
      <div className="uploadingComponent-container-main">
        <b className="uploadingComponent-container-main-text bold small">PROCESO DE CARGA DE DATOS</b>
        <div className="uploadingComponent-container-main-line"/>
        <div className="uploadingComponent-container-main-list">
          {list?.map((item: any, index: any) => (
            <div className="uploadingComponent-container-main-list-item" key={index}>
              <b className="uploadingComponent-container-main-list-item-text regular medium">{item?.name}</b>
              <div className="uploadingComponent-container-main-list-item-bar">
                <div className={getProgressBarClass(item?.status)}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UploadingComponent;