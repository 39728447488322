import React, { useEffect, useState } from 'react'
import MainButton from '../../../MainButton/MainButton';
import './UserPolizasComponent.css'
import { useNavigate } from 'react-router-dom';
import Moment from 'moment/moment'
import { getCheckoutData, getPolizaData } from '../../../../actions';
import { estadoCoberturaList } from '../../../../util/EstadoCoberturaListColors';

const UserPolizasComponent = (props: any) => {

    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate();
    const [polizaData, setPolizaData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [packageData, setPackageData] = useState<any>(null)

    useEffect(() => {
        getPolizaData(props.data?.id, setPolizaData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if(polizaData) getCheckoutData(polizaData?.checkoutId, setCheckoutData, setLoading)
    }, [polizaData])

    useEffect(() => {
        if(checkoutData) setPackageData(checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0])
    }, [checkoutData])

    const getFechaVigente = () => {
        return Moment(props.data?.date).add(1, 'year').format('DD/MM/YYYY')
    }

    const getPolizaNumber = () => {
        if(polizaData && polizaData?.number !== "0") {
            return <p className='userPolizasComponent-container-closed-text regular medium'>POLIZA NRO &nbsp;<ul style={{color: estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}}>{polizaData?.number}</ul></p>
        } return <p className='userPolizasComponent-container-closed-text regular medium'>COBERTURA NRO &nbsp;<ul style={{color: estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}}>{props.data?.id}</ul></p>
    }

    return (
        <div className='userPolizasComponent-container'>
            <div className='userPolizasComponent-container-closed'>
                {getPolizaNumber()}
                <p className='userPolizasComponent-container-closed-text regular medium'>{packageData?.description}</p>
                <p className='userPolizasComponent-container-closed-text regular medium'></p>
                <div className='userPolizasComponent-container-closed-arrowContainer'>
                    <div
                        onClick={() => setOpen(!open)} 
                        className='userPolizasComponent-container-closed-arrowContainer-wrapper'
                        style={{transform: !open ? 'rotate(0deg)' : 'rotate(180deg)'}} 
                    >
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z" fill="var(--primary500)"/>
                        </svg>
                    </div>
                </div>
            </div>
            {open && <div className='userPolizasComponent-container-opened'>
                <p className='userPolizasComponent-container-opened-text regular medium'>ESTADO &nbsp;<ul style={{color: estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}}>{estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}</ul></p>
                <p className='userPolizasComponent-container-opened-text regular medium'>{'FECHA DE ALTA ' + Moment(props.data?.date).format('DD/MM/YYYY')}</p>
                <p className='userPolizasComponent-container-opened-text regular medium'>{'VIGENTE HASTA ' + getFechaVigente()}</p>
                <div className='userPolizasComponent-container-opened-buttonContainer'>
                    <div className='userPolizasComponent-container-opened-buttonContainer-wrapper'>
                        <MainButton
                            text='VER'
                            onPress={() => navigate('/insurance_policies/' + props.data?.id + '#Detalle%20de%20cobertura')}
                        />    
                    </div>
                </div>
            </div>}
        </div>
    );

}

export default UserPolizasComponent;