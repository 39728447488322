import axios from './instanceActions'
import {
    editUserById,
    netGetSucursalList,
} from "../net/Connector";

export const actionGetSucursalList = (setSucursalList: any) => {
    axios.get(netGetSucursalList())
        .then((response) => {
            setSucursalList(response.data)
        })
        .catch((error) => {
        })
};

export const actionEditSeller = (data: any, userId: any, setLoading: any, setSuccess: any, setError: any) => {
    const fastUserData = data;
    setLoading(true)
    axios.patch(editUserById(userId), fastUserData)
        .then(response => {
            setLoading(false)
            setSuccess(true)
        })
        .catch(error => {
            setLoading(false)
            setError(error?.response?.data?.errors[0])
        });
}