import React, { useEffect, useRef, useState } from 'react';
import './PasswordRecoveryCode.css';
import cards from '../../../../assets/images/Cards3D.png';
import MainButton from '../../../../components/MainButton/MainButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  recoverySuccessCode,
  resendEmailCode
} from '../../../../actions';
import { sonLogo } from '../../../../data';

const PasswordRecoveryCode = (props: any) => {

  const [inputError, setInputError] = useState(null)
  const [resendSuccess, setResendSuccess] = useState(false)
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [code6, setCode6] = useState('');

  const input2 = useRef<HTMLInputElement>(null);
  const input3 = useRef<HTMLInputElement | null>(null);
  const input4 = useRef<HTMLInputElement | null>(null);
  const input5 = useRef<HTMLInputElement | null>(null);
  const input6 = useRef<HTMLInputElement | null>(null);

  const location: any = useLocation();
  const email: string = location.state?.userName;
  const [id, setId] = useState<string>(location.state?.id);
  
  const navigate = useNavigate()

  useEffect(() => {
    document.addEventListener('paste', function (event: any) {
      var clipText = event.clipboardData.getData('Text');
      if (clipText.charAt(0)) {
        setCode1(clipText.charAt(0))
      }
      if (clipText.charAt(1)) {
        setCode2(clipText.charAt(1))
      }
      if (clipText.charAt(2)) {
        setCode3(clipText.charAt(2))
      }
      if (clipText.charAt(3)) {
        setCode4(clipText.charAt(3))
      }
      if (clipText.charAt(4)) {
        setCode5(clipText.charAt(4))
      }
      if (clipText.charAt(5)) {
        setCode6(clipText.charAt(5))
      }
    });
  }, []);

  const isDisabled = () => {
    if (code1.length === 1 &&
        code2.length === 1 &&
        code3.length === 1 &&
        code4.length === 1 &&
        code5.length === 1 &&
        code6.length === 1) {
        return false
    }
    return true
}

  return (
    <div className='passwordRecoveryCode-container'>
      <div className='passwordRecoveryCode-container-main'>
        <img className='passwordRecoveryCode-container-main-logo' src={sonLogo} />
        <p className='passwordRecoveryCode-container-main-title'>Ingresá el código que enviamos a tu email</p>
        <p className='passwordRecoveryCode-container-main-subTitle'>Lo enviamos a {email}<br/>No olvides chequear en correo no deseado.</p>
        <div className="passwordRecoveryCode-container-main-codeContainer">
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              id='input1'
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code1}
              onChange={(e: any) => {
                setCode1(e.target.value)
                if (e.target.value !== '') {
                  setCode2('')
                  input2.current!.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              ref={input2}
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code2}
              onChange={(e: any) => {
                setCode2(e.target.value)
                if (e.target.value !== '') {
                  setCode3('')
                  input3.current!.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              ref={input3}
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code3}
              onChange={(e: any) => {
                setCode3(e.target.value)
                if (e.target.value !== '') {
                  setCode4('')
                  input4.current!.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              ref={input4}
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code4}
              onChange={(e: any) => {
                setCode4(e.target.value)
                if (e.target.value !== '') {
                  setCode5('')
                  input5.current!.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              ref={input5}
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code5}
              onChange={(e: any) => {
                setCode5(e.target.value)
                if (e.target.value !== '') {
                  setCode6('')
                  input6.current!.focus();
                }
              }}
              maxLength={1}
            />
          </div>
          <div className="passwordRecoveryCode-container-main-codeContainer-inputContainer">
            <input
              ref={input6}
              className="passwordRecoveryCode-container-main-codeContainer-inputContainer-input"
              value={code6}
              onChange={(e: any) => setCode6(e.target.value)}
              maxLength={1}
            />
          </div>
        </div>
        <p className="passwordRecoveryCode-container-main-resendText">
          ¿No te llegó el mail?
          <p
            id='reenviar verificacion'
            style={{ cursor: 'pointer', margin: 0 }}
            onClick={() => {
              setResendSuccess(false)
              resendEmailCode(email, setResendSuccess, setId)
            }}
          ><strong>&nbsp;Reenviar</strong></p>
        </p>
        <p className="passwordRecoveryCode-container-main-resendSuccessText">{resendSuccess ? 'Enviamos el código nuevamente' : ''}&nbsp;</p>
        <div className='passwordRecoveryCode-container-main-buttonWrapper'>
          <MainButton
            id='validarEmail'
            disabled={isDisabled()}
            text='VALIDAR EMAIL'
            onPress={() => recoverySuccessCode(id, (code1 + code2 + code3 + code4 + code5 + code6), navigate)}
          />
        </div>
        <MainButton
          id='Volver'
          text='Volver'
          onPress={() => navigate(-1)}
          sinFondo
        />
      </div>
      <div className='passwordRecoveryCode-container-cardsContainer'>
        <img className='passwordRecoveryCode-container-cardsContainer-img' src={cards} />
      </div>
    </div>
  );
}

export default PasswordRecoveryCode;