import React, { useEffect, useState } from 'react'
import './ListMetrics.css'

const ListMetrics = (props: any) => {

    const [list, setList] = useState<any>(null)
    const [parsedList, setParsedList] = useState<any>(null)

    useEffect(() => {
        let parsedData: any;
        try {
            parsedData = JSON.parse(props.list);
            setParsedList(parsedData)
        } catch (error) {
            parsedData = props.list;
            setParsedList(parsedData)
        }
    }, [props.list]);

    useEffect(() => {
        let auxList: any = [];
        if(props.list) auxList = [...JSON.parse(props.list)]
        const item: any = { label: '--', value: '--' };
      
        while (auxList?.length < 3 || auxList === null) {
          auxList.push(item);
        }
      
        setList(auxList);
    }, [parsedList])

    return (
        <>
            {parsedList?.length != 1 && <div className='listMetrics-container'>
                <p className='listMetrics-container-title bold'>{props.title}</p>
                {list?.map((b: any, index: any) => (
                    <div key={index} className='listMetrics-container-item'>
                        <div className='listMetrics-container-item-label'>
                            {b.icon && <span className="material-symbols-rounded" style={{ fontSize: 20 }}>{b.icon}</span>}
                            <b className='listMetrics-container-item-label-text regular medium'>{b.label}</b>
                        </div>
                        <p className='listMetrics-container-item-value bold large'>{b.value}</p>
                    </div>
                ))}
            </div>}
        </>
    );

}

export default ListMetrics;