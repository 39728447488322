import React, { useState } from 'react'
import './DateComponent.css'
import Moment from 'moment/moment'

const DateComponent = (props: any) => {

    const [data] = useState(Moment(props.data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'))

    return <p className='dateComponent-text'>{data === 'Invalid date' ? '' : data}</p>
}

export default DateComponent;