import React from 'react'
import './ImageModal.css'

const ImageModal = (props: any) => {

    return (
        <div className='imageModal-container'>
            <img className='imageModal-container-img' src={props.img}/>
        </div>
    );
}

export default ImageModal;