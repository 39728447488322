import React, { useEffect, useState } from 'react'
import './CopyComponent.css'
import { ReactComponent as Copy } from '../../../assets/images/svgs/contentCopy.svg';
import {ReactComponent as Ok} from '../../../assets/images/svgs/ok.svg';

const CopyComponent = (props: any) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleIconClick=()=>{
        setIsCopied(true);
    }

    useEffect(() => {
        setIsCopied(false)
    }, [props.show])

    return (
        <div 
            key={props.key}
            className='copyComponent-container' 
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave} 
            onClick={props.show ? handleIconClick : () => {}}
            style={{ visibility: props.show ? 'visible' : 'hidden' }}
        >
                <div className="copyComponent-container-tooltip">
                    {!isCopied ? <span className="copyComponent-container-tooltiptext">Copiar</span> : <span className="copyComponent-container-tooltiptext">¡Copiado!</span>}
                </div>
                {!isCopied ? 
                    <span className="material-symbols-rounded" style={{ color: 'var(--secondary500)', marginLeft: 10, fontSize: 16}}>content_copy</span>
                    : 
                    <span className="material-symbols-rounded" style={{ color: 'var(--successPrimary)', marginLeft: 10, fontSize: 16}}>done</span>
                }
        </div>
    );
}

export default CopyComponent;       
