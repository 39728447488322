import React from 'react'
import './TableHeader.css'
import UserAdminProfile from '../UserAdminProfile/UserAdminProfile';

const TableHeader = (props: any) => {

    return (
        <div className='tableHeader-container'>
            <div className='tableHeader-container-wrap'>
                <UserAdminProfile
                    optionList={props.userList}
                    userData={props.userData}
                    mini
                />
            </div>
        </div>
    );

}

export default TableHeader;