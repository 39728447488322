import React, { useEffect, useState } from 'react'
import './EditarSucursalModal.css'
import Exit from '../../../assets/images/svgs/exit.svg';
import { ReactSVG } from 'react-svg'
import MainButton from '../../MainButton/MainButton';
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/success.json'
import { actionEditSuperSeller, actionsCreateItem } from '../../../actions';

const EditarSucursalModal = (props: any) => {

    const [nombreSucursal, setNombreSucursal] = useState<string>('')
    const [telefono, setTelefono] = useState<string>('')
    const [emailSucursal, setEmailSucursal] = useState<string>('')

    const [apellidoGerente, setApellidoGerente] = useState<string>('')
    const [nombreGerente, setNombreGerente] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const validateEmail = (e: string) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
            return false;
        } return true
    }

    const isValidName = (e: string) => {
        if(!(/^\s/.test(e))) {
            return (/^[a-zA-Z\u00C0-\u00FF _]*$/.test(e))
        } return false
    };

    const isDisabled = () => {
        return (!validateEmail(emailSucursal) || nombreSucursal === '' || telefono === '' || !isValidName(apellidoGerente) || !isValidName(nombreGerente))
    }

    useEffect(() => {
        if(props.editData) {
            setNombreSucursal(props.editData[2])
            setTelefono(props.editData[3])
            setEmailSucursal(props.editData[4])
            setApellidoGerente(props.editData[5]?.split(',')?.map((str: any) => str.trim())[0])
            setNombreGerente(props.editData[5]?.split(',')?.map((str: any) => str.trim())[1])
        }
    }, [props.editData])

    return (
        <React.Fragment>
            {success ? 
                <div className='editarSucursalModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', marginBottom: 30, cursor: 'pointer'}}
                        onClick={() => {
                            props.onDismiss()
                            window.location.reload();
                        }}
                    />
                     <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: sentSuccess,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice'
                            }
                        }}
                        width={223}
                        isStopped={false}
                        isPaused={false}
                        style={{margin: 0}}
                    />
                    <p className='editarSucursalModal-container-title bold'>¡Excelente!</p>
                    <p className='editarSucursalModal-container-subTitle regular large'>Los cambios se guardaron exitosamente.</p>
                    <div className='editarSucursalModal-container-buttonWrap'>
                        <MainButton
                            text='ACEPTAR'
                            onPress={() => {
                                props.onDismiss()
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
                :
                <div className='editarSucursalModal-container'>
                    <ReactSVG 
                        src={Exit}
                        style={{marginLeft: 'auto', cursor: 'pointer'}}
                        onClick={() => props.onDismiss()}
                    />
                    <p className='editarSucursalModal-container-title bold'>Editar sucursal</p>
                    <p className='editarSucursalModal-container-inputTitle bold'>Datos de la sucursal</p>
                    <div className='editarSucursalModal-container-inputList'>
                        <CustomTextInput
                            label='NOMBRE'
                            onChange={(e: any) => {
                                setNombreSucursal(e.target.value)
                                setError('')
                            }}
                            value={nombreSucursal}
                            success={nombreSucursal.length > 0}
                            required
                            errorMessage={error}
                        />
                        <CustomTextInput
                            label='TELÉFONO'
                            onChange={(e: any) => setTelefono(e.target.value.replace(/[^0-9]+/g, ''))}
                            value={telefono}
                            success={telefono.length > 0}
                            required
                        />
                        <CustomTextInput
                            label='E-MAIL'
                            placeholder='nombre@ejemplo.com'
                            onChange={(e: any) => setEmailSucursal(e.target.value)}
                            value={emailSucursal}
                            error={(emailSucursal.length > 0 && !validateEmail(emailSucursal)) || error}
                            errorMessage={error}
                            success={validateEmail(emailSucursal)}
                            required
                        />
                    </div>
                    <p className='editarSucursalModal-container-inputTitle bold'>Datos del gerente</p>
                    <div className='editarSucursalModal-container-inputList'>
                        <CustomTextInput
                            label='APELLIDO'
                            onChange={(e: any) => setApellidoGerente(e.target.value)}
                            value={apellidoGerente}
                            success={apellidoGerente.length > 0}
                            required
                        />
                        <CustomTextInput
                            label='NOMBRE'
                            onChange={(e: any) => setNombreGerente(e.target.value)}
                            value={nombreGerente}
                            success={nombreGerente.length > 0}
                            required
                        />
                    </div>
                    <div className='editarSucursalModal-container-buttonList'>
                        <MainButton
                            text='CANCELAR'
                            sinFondo
                            onPress={() => props.onDismiss()}
                        />
                        <MainButton
                            text={loading ? 'GUARDANDO...' : 'GUARDAR'}
                            disabled={isDisabled()}
                            onPress={() => {
                                const data = {
                                    "name": nombreGerente,
                                    "lastName": apellidoGerente,
                                    "branchName": nombreSucursal,
                                    "userName": emailSucursal,
                                    "phone": telefono
                                }
                                actionEditSuperSeller(data, props.editData[1], setLoading, setSuccess, setError)
                            }}
                        />
                    </div>
                </div>
            }
        </React.Fragment>
    );

}

export default EditarSucursalModal;