import React from 'react'
import { ReactSVG } from 'react-svg';
import PaintBucket from '../../assets/images/svgs/paintBucket.svg';
import DownGreyArrow from '../../assets/images/svgs/downGreyArrow.svg';
import { ReactComponent as DownWhiteArrow } from '../../assets/images/svgs/downWhiteArrow.svg';
import Bell from '../../assets/images/svgs/bell.svg';
import './HedearIcons.css'
import { countries } from '../../data';

const HedearIcons = (props: any) => {

    const renderCurrentCountry = () => {
        const country = countries.find(c => c.id === props.countryId);
        if (country != null) {
            const Logo: any = country?.img;
            return <Logo/>
        }
    }

    return (
        <div className='hedearIcons-container-main-icons'>
            {/* {countries?.find(c => c.id === props.countryId)?.img && <ReactSVG src={PaintBucket}/>}
            {countries?.find(c => c.id === props.countryId)?.img && <ReactSVG src={DownGreyArrow}/>}
            {countries?.find(c => c.id === props.countryId)?.img && <div className='hedearIcons-container-main-icons-country'>
                {renderCurrentCountry()}
                &nbsp;
                {countries.length > 1 && <DownWhiteArrow/>}
            </div>}
            <ReactSVG src={Bell}/> */}
        </div>
    );

}

export default HedearIcons;