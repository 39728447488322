import React, { useEffect, useState } from 'react'
import './TraficoWebCard.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'
import GraphMetrics from '../GraphMetrics/GraphMetrics';
import ListMetrics from '../ListMetrics/ListMetrics';

const TraficoWebCard = (props: any) => {

    const [auxDataList, setAuxDataList] = useState<any>(null)

    useEffect(() => {
        if(props.data[6]) setAuxDataList(JSON.parse(props.data[6]))
    }, [props.data])

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }

    const traficoWebMethods: any = {
        "grouper": {
            name: "traficoWebMethods.grouper",
            methodComponent: GraphMetrics,
        },
        "container": {
            name: "traficoWebMethods.container",
            methodComponent: ListMetrics,
        },
    }

    const CardComponent: any = traficoWebMethods[props.data[1]].methodComponent;

    const getWidthSection = () => {
        switch (props.data[1]) {
            case "grouper":
                return '30%'
            case 'container':
                return '34.2%'
            default:
                return '100%'
        }
    }

    return (
        <div className='traficoWebCard-container' style={{ width: getWidthSection() }}>
            {props.data[1] === "grouper" ?
                <div className='traficoWebCard-container-rowList'>
                    {auxDataList?.map((a: any, index: any) => (
                        <CardComponent
                            value={a.value}
                            label={a.label}
                            key={index}
                            icon={a?.icon}
                        />
                    ))}
                </div>
                :
                <CardComponent
                    list={props.data[6]}
                    title={props.data[3]}
                />
            }
        </div>
    );

}

export default TraficoWebCard;