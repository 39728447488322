import React, { useEffect, useState } from 'react'
import './TableSubSections.css'

const TableSubSections = (props: any) => {

    const [selectedSection, setSelectedSection] = useState<any>(null);

    useEffect(() => {

    }, [])

    return (
        <div className='tableSubSections-container'>
            {props.data?.filters?.map((section: any, index: any) => (
                <div 
                    key={index}
                    className='tableSubSections-container-item'
                    style={{ 
                        borderBottom: props.subSectionSelected === section?.text ? '3px solid var(--primary500)' : 'none',
                        cursor: props.subSectionSelected === section?.text ? 'auto' : 'pointer' 
                    }}
                    onClick={() => props.sectionChange(section)}
                >
                    <b 
                        className='tableSubSections-container-item-text bold large'
                        style={{ color: props.subSectionSelected === section?.text ? 'var(--gray700)' : 'var(--gray300)'}}
                    >
                        {section?.text}
                    </b>
                </div>
            ))}
        </div>
    );
}

export default TableSubSections;