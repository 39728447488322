import jsPDF from 'jspdf';
import logoCarfacil from '../assets/images/logoCarfacilColor.png';
import logoWecover from '../assets/images/logoWecoverColor.png';
import Moment from 'moment/moment';

export const createPdf = (offer: any, quote: any, selectedPackages: any, insurers: any, localidadTextValue: any) => {
    const doc: any = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'cm',
        format: 'a4', // 'a3', 'a4', 'a5', etc.
    });

    //PDF HEADER
    generateHeader(doc, quote);

    //PDF TITULAR
    generateTitular(doc, quote, localidadTextValue);

    //PDF VEHICULO
    generateVehiculo(doc, quote);

    //PDF COTIZACIONES
    generateCotizaciones(doc, quote, offer, selectedPackages, insurers);

    //PDF FOOTER
    generateFooter(doc);

    // Save the PDF with a name
    doc.save(quote?.previousQuestions[0]?.label + '_' + quote?.previousQuestions[2]?.label + '_' + quote?.previousQuestions[3]?.label + '_' + quote?.previousQuestions[1]?.label + '.pdf');
}

const generateHeader = (doc: any, quote: any) => {
    //Carfacil Logo
    const imgCarfacilWidth = 2.8; // Adjust the image width as needed
    const imgCarfacilHeight = 0.7; // Adjust the image height as needed
    const imgCarfacilX = 1; // X position
    const imgCarfacilY = 2; // Y position

    // Add image to the PDF
    doc.addImage(logoCarfacil, 'PNG', imgCarfacilX, imgCarfacilY, imgCarfacilWidth, imgCarfacilHeight);

    //Wecover Logo
    const imgWecoverWidth = 2.8; // Adjust the image width as needed
    const imgWecoverHeight = 0.7; // Adjust the image height as needed
    const imgWecoverX = imgCarfacilX + imgCarfacilWidth + 0.25; // X position
    const imgWecoverY = 2; // Y position

    // Add image to the PDF
    doc.addImage(logoWecover, 'PNG', imgWecoverX, imgWecoverY, imgWecoverWidth, imgWecoverHeight);

    // Set font size to 8px
    doc.setFontSize(8);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Set text color gray700
    doc.setTextColor(51, 51, 51);

    // Add "COTIZACIÓN: 0000000" at the top right
    var cotizacionText = "COTIZACIÓN: " + quote?.id;
    var cotizacionX = doc.internal.pageSize.getWidth() - 8.5; // Adjust the multiplier as needed
    var cotizacionY = 2.6; // Adjust the Y position as needed

    doc.text(cotizacionText, cotizacionX, cotizacionY);

    // Add "FECHA: DD/MM/AAAA" at the top right below COTIZACIÓN
    var fechaText = "FECHA: " + Moment(new Date()).format('DD/MM/YYYY'); // You need to implement getCurrentDate() function to get the current date in DD/MM/YYYY format
    var fechaX = doc.internal.pageSize.getWidth() - 3.7; // Adjust the multiplier as needed
    var fechaY = cotizacionY + 0.35; // Adjust the Y position as needed

    doc.text(fechaText, fechaX, fechaY);

    // Reset font style to normal
    doc.setFont('helvetica', 'normal');

    // Add a horizontal line
    var lineX = 1; // Adjust the starting X position
    var lineY = 3.1; // Adjust the Y position
    var lineWidth = doc.internal.pageSize.getWidth() - 1.1; // Adjust the line width
    var lineHeight = 0.01; // Set the line height to 1 pixel

    doc.setLineWidth(lineHeight);
    doc.line(lineX, lineY, lineWidth, lineY);

}

const generateTitular = (doc: any, quote: any, localidadTextValue: any) => {
    //Set Columns for X position
    var column1 = 1;
    var column2 = column1 + 3;
    var column3 = column1 + 9;
    var column4 = column1 + 13;

    //Set Rows for Y position
    var row1 = 4.1;
    var row2 = row1 + 1;
    var row3 = row1 + 1.35;
    var row4 = row1 + 2.35;

    // Set font size to 12px
    doc.setFontSize(12);

    // Set text color gray400
    doc.setTextColor(133, 133, 133);

    // Add "DATOS DEL TITULAR" 
    var titularText = "DATOS DEL TITULAR";
    var titularX = column1; // X position
    var titularY = row1; // Y position

    doc.text(titularText, titularX, titularY);

    // Set text color gray700
    doc.setTextColor(51, 51, 51);

    // Set font size to 8px
    doc.setFontSize(8);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "TIPO DE CLIENTE" 
    var tipoClienteText = "TIPO DE CLIENTE";
    var tipoClienteX = column1; // X position
    var tipoClienteY = row2; // Y position

    doc.text(tipoClienteText, tipoClienteX, tipoClienteY);

    // Add "PROVINCIA" 
    var provinciaText = "PROVINCIA";
    var provinciaX = column1; // X position
    var provinciaY = row3; // Y position

    doc.text(provinciaText, provinciaX, provinciaY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "TIPO DE CLIENTE value" 
    var tpcValueText = "Persona";
    var tpcValueX = column2; // X position
    var tpcValueY = row2; // Y position

    doc.text(tpcValueText, tpcValueX, tpcValueY);

    // Add "Provincia value" 
    var pValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "40_provincesc")[0]?.label?.replace(/"/g, '');
    var pValueX = column2; // X position
    var pValueY = row3; // Y position

    doc.text(pValueText, pValueX, pValueY);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "SITUACIÓN ANTE EL IVA" 
    var situacionIvaText = "SITUACIÓN ANTE EL IVA";
    var situacionIvaX = column3; // X position
    var situacionIvaY = row2; // Y position

    doc.text(situacionIvaText, situacionIvaX, situacionIvaY);

    // Add "LOCALIDAD" 
    var localidadText = "LOCALIDAD";
    var localidadX = column3; // X position
    var localidadY = row3; // Y position

    doc.text(localidadText, localidadX, localidadY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "Tipo de IVA value" 
    var ivaValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "37_ivaafip")[0]?.label;
    var ivaValueX = column4; // X position
    var ivaValueY = row2; // Y position

    doc.text(ivaValueText, ivaValueX, ivaValueY);

    // Add "Localidad value" 
    var lValueText = localidadTextValue;
    var lValueX = column4; // X position
    var lValueY = row3; // Y position

    doc.text(lValueText, lValueX, lValueY);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "CÓDIGO POSTAL" 
    var codigoPostalText = "CÓDIGO POSTAL";
    var codigoPostalX = column1; // X position
    var codigoPostalY = row4; // Y position

    doc.text(codigoPostalText, codigoPostalX, codigoPostalY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "codigo postal value" 
    var cpValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "34_postal_code")[0]?.label;
    var cpValueX = column2; // X position
    var cpValueY = row4; // Y position

    doc.text(cpValueText, cpValueX, cpValueY);

}

const generateVehiculo = (doc: any, quote: any) => {
    //Set Columns for X position
    var column1 = 1;
    var column2 = column1 + 3;
    var column3 = column1 + 9;
    var column4 = column1 + 13;

    //Set Rows for Y position
    var row1 = 8;
    var row2 = row1 + 1;
    var row3 = row1 + 1.35;
    var row4 = row1 + 2.35;

    // Set font size to 12px
    doc.setFontSize(12);

    // Set text color gray400
    doc.setTextColor(133, 133, 133);

    // Add "DATOS DEL VEHÍCULO" 
    var vehiculoText = "DATOS DEL VEHÍCULO";
    var vehiculoX = column1; // X position
    var vehiculoY = row1; // Y position

    doc.text(vehiculoText, vehiculoX, vehiculoY);

    // Set text color gray700
    doc.setTextColor(51, 51, 51);

    // Set font size to 8px
    doc.setFontSize(8);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "MARCA/MODELO/VERSION" 
    var marcaText = "MARCA/MODELO/VERSIÓN";
    var marcaX = column1; // X position
    var marcaY = row2; // Y position

    doc.text(marcaText, marcaX, marcaY);

    // Add "USO" 
    var usoText = "USO";
    var usoX = column1; // X position
    var usoY = row3; // Y position

    doc.text(usoText, usoX, usoY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "MARCA/MODELO/VERSION value" 
    var marcaValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "33_car_version")[0]?.label?.toUpperCase();
    var marcaValueX = column2 + 2; // X position
    var marcaValueY = row2; // Y position

    doc.text(marcaValueText, marcaValueX, marcaValueY);

    // Add "USO value" 
    var usoValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "35_caruse")[0]?.label;
    var usoValueX = column2; // X position
    var usoValueY = row3; // Y position

    doc.text(usoValueText, usoValueX, usoValueY);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "GNC" 
    var gncText = "GNC";
    var gncX = column3; // X position
    var gncY = row3; // Y position

    doc.text(gncText, gncX, gncY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "GNC value" 
    var gncValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "36_gnc")[0]?.label;
    var gncValueX = column4; // X position
    var gncValueY = row3; // Y position

    doc.text(gncValueText, gncValueX, gncValueY);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "AÑO" 
    var añoText = "AÑO";
    var añoX = column1; // X position
    var añoY = row4; // Y position

    doc.text(añoText, añoX, añoY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add "AÑO value" 
    var añoValueText = quote?.previousQuestions?.filter((a: any) => a.questionId === "32_car_year")[0]?.label;
    var añoValueX = column2; // X position
    var añoValueY = row4; // Y position

    doc.text(añoValueText, añoValueX, añoValueY);

}

const generateCotizaciones = (doc: any, quote: any, offer: any, selectedPackages: any, insurers: any) => {
    //Set Columns for X position
    var column1 = 1;
    var column2 = column1 + 3;
    var column3 = column2 + 4.25;
    var column4 = column3 + 3;
    var column5 = column4 + 2;
    var column6 = column5 + 2;
    var column7 = column6 + 2;

    //Set Rows for Y position
    var row1 = 12;
    var row2 = row1 + 1;
    var row3 = row2 + 0.35;
    var row4 = row3 + 0.5;

    // Set font size to 12px
    doc.setFontSize(12);

    // Set text color gray400
    doc.setTextColor(133, 133, 133);

    // Add "COTIZACIONES" 
    var cotizacionesText = "COTIZACIONES";
    var cotizacionesX = column1; // X position
    var cotizacionesY = row1; // Y position

    doc.text(cotizacionesText, cotizacionesX, cotizacionesY);

    // Set text color gray700
    doc.setTextColor(51, 51, 51);

    // Set font size to 8px
    doc.setFontSize(8);

    // Set font to bold
    doc.setFont('helvetica', 'bold');

    // Add "ASEGURADORA" 
    var aseguradoraText = "ASEGURADORA";
    var aseguradoraX = column1; // X position
    var aseguradoraY = row2; // Y position

    doc.text(aseguradoraText, aseguradoraX, aseguradoraY);

    // Add "COBERTURA" 
    var coberturaText = "COBERTURA";
    var coberturaX = column2; // X position
    var coberturaY = row2; // Y position

    doc.text(coberturaText, coberturaX, coberturaY);

    // Add "SUMA ASEGURADA" 
    var sumaAseguradaText = "SUMA ASEGURADA";
    var sumaAseguradaX = column3; // X position
    var sumaAseguradaY = row2; // Y position

    doc.text(sumaAseguradaText, sumaAseguradaX, sumaAseguradaY);

    // Add "CUOTA" 
    var cuotaText = "CUOTA";
    var cuotaX = column5; // X position
    var cuotaY = row2; // Y position

    doc.text(cuotaText, cuotaX, cuotaY);

    // Add "VIGENCIA" 
    var vigenciaText = "VIGENCIA";
    var vigenciaX = column6; // X position
    var vigenciaY = row2; // Y position

    doc.text(vigenciaText, vigenciaX, vigenciaY);

    // Add "REFACTURACIÓN" 
    var refacturacionText = "REFACTURACIÓN";
    var refacturacionX = column7; // X position
    var refacturacionY = row2; // Y position

    doc.text(refacturacionText, refacturacionX, refacturacionY);

    // Set font to normal
    doc.setFont('helvetica', 'normal');

    // Add a horizontal line
    var lineX = column1; // Adjust the starting X position
    var lineY = row3; // Adjust the Y position
    var lineWidth = doc.internal.pageSize.getWidth() - 1.1; // Adjust the line width
    var lineHeight = 0.01; // Set the line height to 1 pixel

    doc.setLineWidth(lineHeight);
    doc.line(lineX, lineY, lineWidth, lineY);

    // INSURER PACKAGES
    const insurerPackages: any = mapInsurerList(insurers, selectedPackages, offer?.packages);

    let currentRowForInsurer = row4;
    insurerPackages?.map((insurer: any) => {
        if(insurer?.packages?.length > 0) currentRowForInsurer = generateInsurerPackages(doc, insurer, currentRowForInsurer);
    })
}

const mapInsurerList = (insurers: any, selectedPackages: any, packages: any) => {
    const res: any = [];
    insurers?.map((insurerItem: any) => {
        let insurer: any = [];
        insurer['insurerId'] = insurerItem?.id;
        insurer['insurerName'] = insurerItem?.name;
        insurer['packages'] = [];
        packages?.map((packageItem: any) => {
            let packageRes: any = [];
            if(selectedPackages?.includes(packageItem?.id) && packageItem?.insurer?.id === insurer?.insurerId) {
                packageRes['name'] = packageItem?.description;
                packageRes['suma'] = packageItem?.coverageGroups[0]?.coverages?.filter((a: any) => a.title === "Suma asegurada")[0]?.formattedValue;
                packageRes['cuota'] = packageItem?.price?.formattedValue;
                packageRes['vigencia'] = 'Anual';
                packageRes['refacturacion'] = 'Mensual';
                insurer['packages'].push(packageRes);
            }
        })
        res.push(insurer);
    })
    return res;
}

const generateInsurerPackages = (doc: any, insurer: any, row4: any) => {
    //Set Columns for X position
    var column1 = 1;
    var column2 = column1 + 3;
    var column3 = column2 + 4.25;
    var column4 = column3 + 3;
    var column5 = column4 + 2;
    var column6 = column5 + 2;
    var column7 = column6 + 2;

    //Set Row for Y position
    let pdfRow = row4;

    // Add "insurerName" 
    var insurerNameText = insurer?.insurerName;
    var insurerNameX = column1; // X position
    var insurerNameY = pdfRow; // Y position

    doc.text(insurerNameText, insurerNameX, insurerNameY);

    const maxHeight = doc.internal.pageSize.height - 1;

    insurer?.packages?.map((packageItem: any) => {

        // Check if the current row exceeds the maximum height
        if (pdfRow > maxHeight) {
            doc.addPage(); // Add a new page
            pdfRow = 1; // Reset Y position (adjust as needed)
        }

        // Add "name" 
        var nameText = packageItem?.name;
        const nameTextLong = doc.splitTextToSize(nameText, 4);
        var nameX = column2; // X position
        var nameY = pdfRow; // Y position

        doc.text(nameTextLong, nameX, nameY);

        // Add "suma" 
        var sumaText = packageItem?.suma;
        var sumaX = column3; // X position
        var sumaY = pdfRow; // Y position

        doc.text(sumaText, sumaX, sumaY);

        // Add "cuota" 
        var cuotaText = packageItem?.cuota;
        var cuotaX = column5; // X position
        var cuotaY = pdfRow; // Y position

        doc.text(cuotaText, cuotaX, cuotaY);

        // Add "vigencia" 
        var vigenciaText = packageItem?.vigencia;
        var vigenciaX = column6; // X position
        var vigenciaY = pdfRow; // Y position

        doc.text(vigenciaText, vigenciaX, vigenciaY);

        // Add "refacturacion" 
        var refacturacionText = packageItem?.refacturacion;
        var refacturacionX = column7; // X position
        var refacturacionY = pdfRow; // Y position

        doc.text(refacturacionText, refacturacionX, refacturacionY);
    
        const increase = 0.5; // Adjust this based on your layout
        pdfRow = pdfRow + (increase * nameTextLong?.length);
    })

    if (insurer?.packages?.length === 0) pdfRow = pdfRow + 1;

    // Add a horizontal line
    var lineX = column1; // Adjust the starting X position
    var lineY = pdfRow; // Adjust the Y position
    var lineWidth = doc.internal.pageSize.getWidth() - 1.1; // Adjust the line width
    var lineHeight = 0.01; // Set the line height to 1 pixel

    doc.setLineWidth(lineHeight);
    doc.line(lineX, lineY, lineWidth, lineY);

    return pdfRow + 0.5;
}

const generateFooter = (doc: any) => {

    var pageWidth = doc.internal.pageSize.getWidth();
    var pageHeight = doc.internal.pageSize.getHeight();

    // Add "footer" 
    var footerText = 'WeCover | Whatsapp +54 9 11 6960-2118 | Email: hola@wecover.co';
    var footerWidth = doc.getStringUnitWidth(footerText) * 8 / doc.internal.scaleFactor;
    var footerX = (pageWidth - footerWidth) / 2;
    var footerY = pageHeight - 0.5; // Adjust the margin as needed

    doc.text(footerText, footerX, footerY);
}