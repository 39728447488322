import React, { useState } from 'react';
import './PasswordRecoveryForm.css';
import cards from '../../../../assets/images/Cards3D.png';
import CustomTextInput from '../../../../components/CustomTextInput/CustomTextInput';
import MainButton from '../../../../components/MainButton/MainButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { recoverySuccessNewPassword } from '../../../../actions';
import { ReactSVG } from 'react-svg';
import Check from '../../../../assets/images/svgs/greenCheck.svg'
import { sonLogo } from '../../../../data';

const PasswordRecoveryForm = (props: any) => {

  const [passwordText, setPasswordText] = useState('')
  const [passwordConfirmText, setPasswordConfirmText] = useState('')
  const [lengthCheck, setLengthCheck] = useState(false)
  const [uperCaseCheck, setUperCaseCheck] = useState(false)
  const [numberCheck, setNumberCheck] = useState(false)


  const location: any = useLocation();
  const id: string = location.state?.id;

  const navigate = useNavigate()

  const validatePassword = (e: string) => {
    if (e.length >= 8) {
      if (/\d/.test(e) && /[a-zA-Z]/.test(e)) {
        if (/[A-Z].*\d|\d.*[A-Z]/.test(e)) {
          return true
        }
      }
    }
    return false
  }

  const validatePasswordRedo = () => {
    if (passwordConfirmText.length > 0) {
      if (passwordConfirmText === passwordText) {
        return validatePassword(passwordText)
      }
    } return false
  }

  const handlePasswordChange = (text: string) => {
    if (/\d/.test(text)) {
      setNumberCheck(true)
    } else {
      setNumberCheck(false)
    }
    if (/[A-Z]/.test(text)) {
      setUperCaseCheck(true)
    } else {
      setUperCaseCheck(false)
    }
    if (text.length >= 8) {
      setLengthCheck(true)
    } else {
      setLengthCheck(false)
    }
    setPasswordText(text)
  }

  const isDisabled = () => {
    return !(validatePassword(passwordText) && validatePasswordRedo())
  }

  return (
    <div className='passwordRecoveryForm-container'>
      <div className='passwordRecoveryForm-container-main'>
        <img className='passwordRecoveryForm-container-main-logo' src={sonLogo} />
        <p className='passwordRecoveryForm-container-main-title'>Recuperar contraseña</p>
        <p className='passwordRecoveryForm-container-main-subTitle'>Por favor, ingresá tu nueva contraseña</p>
        <div className='passwordRecoveryForm-container-main-inputWrapperContra'>
          <CustomTextInput
            label='Contraseña'
            onChange={(e: any) => handlePasswordChange(e.target.value)}
            value={passwordText}
            ispassword="true"
          />
          <div className='passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer'>
            <p className='passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer-text'>Debe contener:</p>
            {lengthCheck && <ReactSVG style={{marginLeft: 2.5, marginRight: 2.5}} src={Check}/>}
            <p className='passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer-text' style={{marginLeft: lengthCheck ? 0 : 2.5}}>más de 8 caracteres</p>
            {uperCaseCheck && <ReactSVG style={{marginLeft: 2.5, marginRight: 2.5}} src={Check}/>}
            <p className='passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer-text' style={{marginLeft: uperCaseCheck ? 0 : 2.5}}>1 mayúscula y</p>
            {numberCheck && <ReactSVG style={{marginLeft: 2.5, marginRight: 2.5}} src={Check}/>}
            <p className='passwordRecoveryForm-container-main-inputWrapperContra-underlineContainer-text' style={{marginLeft: numberCheck ? 0 : 2.5}}>1 número.</p>
          </div>
        </div>
        <div className='passwordRecoveryForm-container-main-inputWrapperReContra'>
          <CustomTextInput
            label='Repetir contraseña'
            onChange={(e: any) => setPasswordConfirmText(e.target.value)}
            value={passwordConfirmText}
            ispassword="true"
            error={passwordText === passwordConfirmText || passwordConfirmText.length === 0 ? null : 'La contraseña ingresada debe ser idéntica en ambos casos.'}
          />
        </div>
        <div className='passwordRecoveryForm-container-main-buttonWrapper'>
          <MainButton
            id='recuperarContraseña'
            disabled={isDisabled()}
            text='RECUPERAR CONTRASEÑA'
            onPress={() => recoverySuccessNewPassword(id, passwordText, navigate)}
          />
        </div>
        <MainButton
          id='Volver'
          text='Volver'
          onPress={() => navigate(-1)}
          sinFondo
        />
      </div>
      <div className='passwordRecoveryForm-container-cardsContainer'>
        <img className='passwordRecoveryForm-container-cardsContainer-img' src={cards} />
      </div>
    </div>
  );
}

export default PasswordRecoveryForm;