import React, { useEffect, useState } from 'react'
import { actionDashboard } from '../../actions';
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import CardList from '../../components/DashboardComponents/CardList/CardList';
import './Dashboard.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import Selector from '../../components/Selector/Selector';
import SocialLinks from '../../components/SocialLinks/SocialLinks';

const Dashboard = (props: any) => {

    const [dashboardData, setDashboardData] = useState<any>(null)
    const [selectedFilter, setSelectedFilter] = useState<any>({ label: 'Últimos 30 días', days: 30 })

    const filterList = [
        {
            label: 'Último día',
            days: 1
        },
        {
            label: 'Últimos 7 días',
            days: 7
        },
        {
            label: 'Últimos 30 días',
            days: 30
        },
        {
            label: 'Últimos 3 meses',
            days: 90
        },
        {
            label: 'Últimos 6 meses',
            days: 180
        },
        {
            label: 'Último año',
            days: 365
        }, 
    ]

    useEffect(() => {
        actionDashboard(setDashboardData);
    }, [])

    return (
        <div className='dashboard-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='dashboard-container-main'>
                <div className='dashboard-container-main-hedear'>
                    <div className='dashboard-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                    />
                </div>
                <div className='dashboard-container-main-data' id="Dashboard_Component_Id">
                    <div className='dashboard-container-main-data-title'>
                        <h2 className='dashboard-container-main-data-title-text bold'>Mi dashboard</h2>
                        <div className='dashboard-container-main-data-title-selectorWrapper'>
                            <Selector
                                itemSelected={(a: any) => setSelectedFilter(a)}
                                sortList={filterList}
                                default={selectedFilter.label}
                            />
                        </div>
                    </div>
                    <div className='dashboard-container-main-data-cardList'>
                        {dashboardData?.charts?.map((a: any) => (
                            <div className='dashboard-container-main-data-cardList-list' key={a.id}>
                                <CardList data={a} dashboardId={dashboardData?.id} days={selectedFilter?.days}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <SocialLinks/>
        </div>
    );

}

export default Dashboard;