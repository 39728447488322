import React, { useState } from 'react'
import './GuardarCotizacionModal.css'
import CustomTextInput from '../../CustomTextInput/CustomTextInput';
import MainButton from '../../MainButton/MainButton';

const GuardarCotizacionModal = (props: any) => {

    const [text, setText] = useState<string>('');

    return (
        <div className='guardarCotizacionModal-container'>
            <span 
                className="material-symbols-rounded" 
                style={{ 
                    color: 'var(--gray700)', 
                    fontSize: 20,
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    cursor: 'pointer'
                }}
                onClick={() => props.dismiss()}
            >close</span>
            <h6 className='guardarCotizacionModal-container-title bold'>Guardar cotización</h6>
            <b className='guardarCotizacionModal-container-subTitle regular large'>Ingresá un nombre o descripción para identificarla</b>
            <div className='guardarCotizacionModal-container-inputWrapper'>
                <CustomTextInput
                    placeholder='EJ: JUAN PÉREZ'
                    onChange={(e: any) => setText(e.target.value)}
                    value={text}
                />
            </div>
            <div className='guardarCotizacionModal-container-buttons'>
                <MainButton
                    text='CANCELAR'
                    inverted
                    onPress={() => props.dismiss()}
                />
                <MainButton
                    text='GUARDAR'
                    disabled={text === ''}
                    onPress={() => props.guardar(text)}
                />
            </div>
        </div>
    );

}

export default GuardarCotizacionModal;