import React, { ComponentElement, useEffect, useState } from "react";
import { actionDashboardList } from "../../../actions";
import CodCupCard from "../CodCupCard/CodCupCard";
import GeneralCard from "../GeneralCard/GeneralCard";
import "./CardList.css";
import MainButton from "../../MainButton/MainButton";
import TraficoWebCard from "../TraficoWebCard/TraficoWebCard";
import DetalleItemCard from "../DetalleItemCard/DetalleItemCard";
import ComprasCard from "../ComprasCard/ComprasCard";

const CardList = (props: any) => {
  const [list, setList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<any>({
    label: process.env.REACT_APP_DEFAULT_ITEM_LABEL,
  });

  useEffect(() => {
    let isMounted = true; // Add a flag to track if the component is still mounted
    const data = {
      dashboardId: props.dashboardId,
      chartId: props.data?.id,
      conditions: {
        categoryId: itemSelected?.value,
        daysFilter: props.days,
      },
    };

    actionDashboardList(setList, data, setLoading);

    // Cleanup function to cancel any ongoing tasks when the component is unmounted
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isMounted = false;
    };
  }, [props.days, itemSelected]);

  const cardMethods: any = {
    EstadisticasGlobales: {
      name: "cardMethods.general",
      methodComponent: GeneralCard,
    },
    CodCupCard: {
      name: "cardMethods.codCup",
      methodComponent: CodCupCard,
    },
    TraficoWeb: {
      name: "cardMethods.trafico",
      methodComponent: TraficoWebCard,
    },
    DetalleItem: {
      name: "cardMethods.detalle",
      methodComponent: DetalleItemCard,
    },
    Compras: {
      name: "cardMethods.compras",
      methodComponent: ComprasCard,
    },
  };

  const CardComponent: any = cardMethods[props.data?.type].methodComponent;

  return (
    <div className="cardList-container">
      <div className="cardList-container-labels">
        <h6 className="cardList-container-labels-title bold">
          {props.data.title}
        </h6>
        {props.data?.visualization?.buttomInfo && (
          <p className="cardList-container-labels-label">
            {props.data.visualization.buttomInfo.label}
          </p>
        )}
      </div>
      {loading ? (
        <div className="cardList-container-loading">
          <CardComponent
            data={{}}
            config={props.data.visualization}
            loading={loading}
          />
        </div>
      ) : list?.data?.length === 0 ? (
        <div className="cardList-container-emptyList">
          <p className="cardList-container-emptyList-title">
            {props.data?.visualization?.empty?.title}
          </p>
          {props.data?.visualization?.empty?.button?.label && (
            <MainButton
              text={props.data?.visualization?.empty?.button?.label}
              disabled
              onPress={() => {}}
            />
          )}
        </div>
      ) : (
        <div
          className="cardList-container-list"
          style={{ width: props.data?.type === "Compras" ? "65%" : "90%" }}
        >
          {list?.data?.map((a: any, index: number) => (
            <CardComponent
              key={index}
              data={a}
              config={props.data?.visualization}
              isFirst={index === 0}
              isLast={index === list?.data?.length - 1}
              loading={loading}
              setItemSelected={setItemSelected}
              itemSelected={itemSelected}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CardList;
