import React from 'react'
import './CodCupCard.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'

const CodCupCard = (props) => {

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }
    return (
        <div 
            className='codCupCard-container'
            style={{
                borderBottomLeftRadius: props.isFirst ? 15 : 0,
                borderBottomRightRadius: props.isLast ? 15 : 0,
                borderTopLeftRadius: props.isFirst ? 15 : 0,
                borderTopRightRadius: props.isLast ? 15 : 0
            }}
        >
            <div className='codCupCard-container-cupons'>
            <span className="material-symbols-rounded">loyalty</span>
                <p className='codCupCard-container-cupons-value'>{props.data[1]}</p>
            </div>
            <div className='codCupCard-container-data'>
                <p className='codCupCard-container-data-title'>{props.data[3]}</p>
                <p className='codCupCard-container-data-subTitle'>{props.data[0]}</p>
                <p className='codCupCard-container-data-vence'>{props.data[2]}</p>
            </div>
            <div 
                className='codCupCard-container-line'
                style={{
                    backgroundColor: props.isLast ? 'transparent' : 'var(--gray300)'
                }}
            ></div>
        </div>
    );

}

export default CodCupCard;