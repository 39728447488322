import React, { useState } from 'react'
import './TitleAndSubTitleComponent.css'

const TitleAndSubTitleComponent = (props: any) => {

    const [data] = useState<any>(JSON.parse(props.data))

    return (
        <div className='titleAndSubTitleComponent-container'>
            <p className='titleAndSubTitleComponent-container-title'>{data?.h1}</p>
            <p className='titleAndSubTitleComponent-container-subTitle'>{data?.h2}</p>
        </div>
    );
}

export default TitleAndSubTitleComponent;