import React, { useEffect, useState } from 'react'
import './CopyTextComponent.css'
import CopyComponent from '../CopyComponent/CopyComponent';

const CopyTextComponent = (props: any) => {

    const [data, setData] = useState<any>(null)
    const [isTitleOver, setIsTitleOver] = useState(false);
    const [isMouseOverCopyTitle, setIsMouseOverCopyTitle] = useState(false);

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    useEffect(() => {
        handleParse();
    }, [props.data])

    const handleCopyTitle = (e:any) => {
        e.stopPropagation();
        const textToCopy = data
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
        })
        .catch((err) => {
        });
    };

    const handleTitleOver = () => {
        setIsTitleOver(true);
    };

    const handleTitleOut = () => {
        setIsTitleOver(false);
    };

    const handleMouseEnterCopyTitle=()=>{
        setIsMouseOverCopyTitle(true);
    }

    const handleMouseLeaveCopyTitle=()=>{
        setIsMouseOverCopyTitle(false);
    }

    return (
        <div 
            className="copyTextComponent-container" 
            onClick={handleCopyTitle}
            onMouseOver={handleTitleOver}
            onMouseOut={handleTitleOut}
        >
            <b className="copyTextComponent-container-text regular medium">{data}</b>
            <CopyComponent 
                show={isTitleOver || isMouseOverCopyTitle} 
                onMouseEnter={handleMouseEnterCopyTitle}
                onMouseLeave={handleMouseLeaveCopyTitle}
            />
        </div>
    );
}

export default CopyTextComponent;