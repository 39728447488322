import React, { useState } from 'react'
import MainButton from '../../../MainButton/MainButton';
import './UserClaimsComponent.css'
import Moment from 'moment/moment'
import { useNavigate } from 'react-router-dom';

const UserClaimsComponent = (props: any) => {

    const [open, setOpen] = useState<boolean>(false)

    const navigate = useNavigate();

    return (
        <div className='userClaimsComponent-container'>
            <div className='userClaimsComponent-container-closed'>
                <p className='userClaimsComponent-container-closed-text regular medium'>FECHA: {Moment(props.data?.createdAt).format('DD/MM/YYYY')}</p>
                <p className='userClaimsComponent-container-closed-text regular medium'>COBERTURA: {props.data?.productInsuredUserId}</p>
                <p className='userClaimsComponent-container-closed-text regular medium'>{props.data?.nombre + ' ' + props.data?.modelo}</p>
                <div className='userClaimsComponent-container-closed-status'>
                    <p 
                        className='userClaimsComponent-container-closed-status-textStatus small regular'
                        style={{
                            backgroundColor: props.data?.status?.type === 'INICIADO' ? 'var(--pendingPrimary)' : props.data?.status?.type === 'EN PROCESO' ? 'var(--warningPrimary)' : props.data?.status?.type === 'ACEPTADO' ? 'var(--successPrimary)' : 'var(--errorPrimary)',
                            color: props.data?.status === 'INICIADO' ? 'var(--gray700)' : 'var(--white)'
                        }}
                    >{props.data?.status?.type}</p>
                    <div className='userClaimsComponent-container-closed-status-arrowContainer'>
                        <div 
                            onClick={() => setOpen(!open)} 
                            style={{transform: !open ? 'rotate(0deg)' : 'rotate(180deg)', }} 
                            className='userClaimsComponent-container-closed-status-arrowContainer-wrapper'
                        >
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893Z" fill="var(--primary500)"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {open && <div className='userClaimsComponent-container-opened'>
                <p className='userClaimsComponent-container-opened-text regular medium'>TIPO: {props.data?.type}</p>
                <p className='userClaimsComponent-container-opened-text regular medium'>ASEGURA: {props.data?.insurerName}</p>
                <p className='userClaimsComponent-container-opened-text regular medium'>CATEGORIA: {props.data?.categoryDescription}</p>
                <div className='userClaimsComponent-container-opened-buttonContainer'>
                    <div className='userClaimsComponent-container-opened-buttonContainer-wrapper'>
                        <MainButton
                            text='VER'
                            onPress={() => navigate('/insurance_policies/' + props.data?.productInsuredUserId + '#Detalle%20de%20cobertura')}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );

}

export default UserClaimsComponent;