import React, { useEffect, useState } from 'react'
import './GenericTableAndFilters.css'
import TableContent from '../../components/TableContent/TableContent';
import {
    actionsTableDataRows,
    actionsTableDownload,
    actionsTableHeader
} from '../../actions';
import Lottie from 'react-lottie';
import sentSuccess from '../../assets/lotties/loading.json'
import TableFilters from '../../components/TableFilters/TableFilters';
import MainButton from '../../components/MainButton/MainButton';
import { useNavigate } from 'react-router-dom';
import Notification from '../Notification/Notification';
import UploadComponent from '../UploadComponent/UploadComponent';
import UploadingComponent from '../UploadingComponent/UploadingComponent';
import TableSubSections from '../TableSubSections/TableSubSections';

const GenericTableAndFilters = (props) => {

    const [chartData, setChartData] = useState(null);
    const [chartDataRows, setChartDataRows] = useState(null);
    const [formData, setFormData] = useState(null);
    const [loadingChart, setLoadingChart] = useState(true);
    const [dataConfig, setDataConfig] = useState({ "page": { "number": 1, "size": 40 } });
    const [notification, setNotification] = useState({ success: false, text: '' });
    const [subSectionSelected, setSubSectionSelected] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        actionsTableHeader(setChartData, setFormData, setChartDataRows, dataConfig, setLoadingChart, props.data.type, props.data.search)
    }, [])

    const handlePaging = (page) => {
        let data = dataConfig
        data.page.number = page;
        actionsTableDataRows(setChartDataRows, data, setLoadingChart, props.data?.search)
    }

    const handleSubSection = (section) => {
        let data = dataConfig;
        const subSectionsFilter = getFilters(formData?.subSections?.filters);
        setSubSectionSelected(section?.text)
        data.filter = removeCommonObjects(data.filter, subSectionsFilter);
        data.filter.push(section?.filter)
        actionsTableDataRows(setChartDataRows, data, setLoadingChart, props.data?.search)
    }

    const getFilters = (filters) => {
        return filters?.map(a => a.filter) || [];
    };    

    const removeCommonObjects = (arrayA, arrayB) => {
        function objectsAreEqual(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2);
        }
        return arrayA.filter(objA => !arrayB.some(objB => objectsAreEqual(objA, objB)));
    }

    useEffect(() => {
        if (formData?.subSections) handleSubSection(formData?.subSections?.filters[0])
    }, [formData])

    return (
        <div className='genericTableAndFilters-container'>
            <div className='genericTableAndFilters-container-title'>
                <div className='genericTableAndFilters-container-title-texts'>
                    <h2 className='genericTableAndFilters-container-title-texts-title bold'>{props.data.title}</h2>
                    {props.data?.subTitle && <b className='genericTableAndFilters-container-title-texts-subTitle regular medium'>{props.data.subTitle}</b>}
                </div>
                {(props.canCreate || props.canCreateCustom) && <div className='genericTableAndFilters-container-title-buttonWrapper'>
                    {props.secondaryCreate &&
                        <b 
                            className='genericTableAndFilters-container-title-buttonWrapper-secondaryCreate'
                            onClick={() => props.secondaryCreate?.action()}
                        >
                            {props.secondaryCreate?.title}
                        </b>
                    }
                    <div className='genericTableAndFilters-container-title-buttonWrapper-primaryCreate'>
                        <MainButton
                            text={props.data.createTitle}
                            inverted
                            onPress={() => {
                                if (props.canCreate) navigate(props.data.createPath)
                                else if (props.canCreateCustom) props.createTableItem()
                            }}
                        />
                    </div>
                </div>}
                <Notification
                    notification={notification}
                    setNotification={setNotification}
                />
            </div>
            {props.canUpload && 
                <div className='genericTableAndFilters-container-uploads'>
                    <UploadComponent/>
                    <UploadingComponent/>
                </div>
            }
            {formData?.subSections &&
                <TableSubSections
                    data={formData?.subSections}
                    sectionChange={handleSubSection}
                    subSectionSelected={subSectionSelected}
                />
            }
            <TableFilters
                chartData={chartData}
                formData={formData}
                dataConfig={dataConfig}
                setDataConfig={setDataConfig}
                sortChange={(data) => actionsTableDataRows(setChartDataRows, data, setLoadingChart, props.data.search)}
                download={(setDownloading) => actionsTableDownload(dataConfig?.filter, dataConfig?.sort, setDownloading, props.data.type)}
                canDownload={props.canDownload}
                canDownloadTxt={props.canDownloadTxt}
                canUpload={props.canUpload}
                type={props.data?.search}
            />
            {loadingChart ?
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: sentSuccess,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={100}
                    height={100}
                    isStopped={false}
                    isPaused={false}
                    style={{ margin: 'auto' }}
                />
                :
                <TableContent
                    headers={chartData?.columnConfig}
                    dataRows={chartDataRows?.data}
                    page={chartDataRows}
                    formData={formData}
                    dataConfig={dataConfig}
                    prevPage={() => handlePaging(chartDataRows.currentPage - 1)}
                    nextPage={() => handlePaging(chartDataRows.currentPage + 1)}
                    emptyText={props.emptyText}
                    title={props.data?.title}
                    idName={props.idName}
                    hasDetails={props.goToDetails}
                    goToDetails={(id) => props.goToDetails(id)}
                    deleteData={props.deleteData}
                    deleteMultipleData={props.deleteMultipleData}
                    idType={props.idType}
                    userData={props.userData}
                    refreshData={() => actionsTableDataRows(setChartDataRows, dataConfig, setLoadingChart, props.data.search)}
                    sendNotification={(data) => setNotification(data)}
                    canEditCustom={props.canEditCustom}
                    editTableItem={props.editTableItem}
                    canDelete={props.canDelete}
                    setEditData={props.setEditData}
                    setGlobalLoading={props.setGlobalLoading}
                />
            }
        </div>
    );
}

export default GenericTableAndFilters;