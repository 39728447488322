import React from 'react'
import './FunnelMetrics.css'
import { useNavigate } from 'react-router-dom';

const FunnelMetrics = (props: any) => {

    const navigate = useNavigate();

    return (
        <div className='funnelMetrics-container' style={{ marginTop: props.index === 0 ? 10 : 0 }}>
            <div className='funnelMetrics-container-label'>
                <div className='funnelMetrics-container-label-icon'>
                    <span className="material-symbols-rounded">{props.data?.icon}</span>
                </div>
                <p className='funnelMetrics-container-label-text regular medium'>{props.data?.label}</p>
            </div>
            <div className='funnelMetrics-container-value'>
                <b className='funnelMetrics-container-value-text bold large'>{props.data?.value}</b>
                {props.data?.link ?
                    <svg style={{ cursor: 'pointer' }} onClick={() => navigate(props.data?.link)} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.700049 11.3C0.516715 11.1167 0.425049 10.8834 0.425049 10.6C0.425049 10.3167 0.516715 10.0834 0.700049 9.90005L4.60005 6.00005L0.700049 2.10005C0.516715 1.91672 0.425049 1.68338 0.425049 1.40005C0.425049 1.11672 0.516715 0.883382 0.700049 0.700049C0.883382 0.516715 1.11672 0.425049 1.40005 0.425049C1.68338 0.425049 1.91672 0.516715 2.10005 0.700049L6.70005 5.30005C6.80005 5.40005 6.87088 5.50838 6.91255 5.62505C6.95422 5.74172 6.97505 5.86672 6.97505 6.00005C6.97505 6.13338 6.95422 6.25838 6.91255 6.37505C6.87088 6.49172 6.80005 6.60005 6.70005 6.70005L2.10005 11.3C1.91672 11.4834 1.68338 11.575 1.40005 11.575C1.11672 11.575 0.883382 11.4834 0.700049 11.3Z" fill="var(--primary500)"/>
                    </svg>
                    :
                    <svg style={{ opacity: 0 }} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.700049 11.3C0.516715 11.1167 0.425049 10.8834 0.425049 10.6C0.425049 10.3167 0.516715 10.0834 0.700049 9.90005L4.60005 6.00005L0.700049 2.10005C0.516715 1.91672 0.425049 1.68338 0.425049 1.40005C0.425049 1.11672 0.516715 0.883382 0.700049 0.700049C0.883382 0.516715 1.11672 0.425049 1.40005 0.425049C1.68338 0.425049 1.91672 0.516715 2.10005 0.700049L6.70005 5.30005C6.80005 5.40005 6.87088 5.50838 6.91255 5.62505C6.95422 5.74172 6.97505 5.86672 6.97505 6.00005C6.97505 6.13338 6.95422 6.25838 6.91255 6.37505C6.87088 6.49172 6.80005 6.60005 6.70005 6.70005L2.10005 11.3C1.91672 11.4834 1.68338 11.575 1.40005 11.575C1.11672 11.575 0.883382 11.4834 0.700049 11.3Z" fill="var(--primary500)"/>
                    </svg>
                }
            </div>
        </div>
    );

}

export default FunnelMetrics;