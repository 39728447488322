import React, { useEffect, useState } from 'react'
import { 
    getUserClientDataPardo,
    getListsArgCarfacil,
    editUserProfileArgPardo,
    actionEditUser
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './UserData.css'
import Moment from 'moment/moment'
import Lottie from 'react-lottie'
import loadingLottie from '../../../assets/lotties/loading.json'
import MainButton from '../../MainButton/MainButton'
import SelectorInput from '../../SelectorInput/SelectorInput'
import Modal from 'react-modal';
import CancelModal from '../../Modals/CancelModal/CancelModal'

const UserData = (props: any) => {

    //ORIGINAL DATA
    const [userClientData, setUserClientData] = useState<any>(null);

    //EDITABLE INPUTS
    const [tipoDeDocumento, setTipoDeDocumento] = useState<any>(null);
    const [nroDeDocumento, setNroDeDocumento] = useState<string>('');
    const [cuitOne, setCuitOne] = useState<string>('');
    const [cuitTwo, setCuitTwo] = useState<string>('');
    const [cuitThree, setCuitThree] = useState<string>('');
    const [sexo, setSexo] = useState<any>(null);
    
    const [telefono, setTelefono] = useState<string>('');
    
    const [situacionIva, setSituacionIva] = useState<any>(null);
    const [estadoCivil, setEstadoCivil] = useState<any>(null);

    const [calle, setCalle] = useState<string>('');
    const [nro, setNro] = useState<string>('');
    const [piso, setPiso] = useState<string>('');
    const [depto, setDepto] = useState<string>('');

    const [ingresosBrutos, setIngresosBrutos] = useState<any>(null);

    //LISTS
    const [tipoDocuList, setTipoDocuList] = useState<any>(null);

    const [sexoList, setSexoList] = useState<any>(null);
    const [estadoCivilList, setEstadoCivilList] = useState<any>(null);
    const [situacionIVAList, setSituacionIVAList] = useState<any>(null);

    const [ingresosBrutosList, setIngresosBrutosList] = useState<any>(null);

    //OTROS
    const [loading, setLoading] = useState<boolean>(false);
    const [cancelAlert, setCancelAlert] = useState<boolean>(false);

    //USE EFFECTS
    useEffect(() => {
        loadHardCodedLists()
        getUserClientDataPardo(props.userId, setUserClientData, props.from, setLoading)
    }, [])

    useEffect(() => {
        if (userClientData) getListsArgCarfacil(setSexoList, setEstadoCivilList)
    }, [userClientData])

    useEffect(() => {
        if(props.enableEdit) loadUserDataForEdit()
    }, [props.enableEdit])

    //FUNCTIONS
    const loadHardCodedLists = () => {
        const tipoDocuListHard = [
            {
                codigo: 'LE',
                descripcion: 'LE'
            },
            {
                codigo: 'CI',
                descripcion: 'CI'
            },
            {
                codigo: 'DU',
                descripcion: 'DNI'
            },
            {
                codigo: 'PAS',
                descripcion: 'Pasaporte'
            },
            {
                codigo: 'DU',
                descripcion: 'CUIT'
            }
        ]
        setTipoDocuList(tipoDocuListHard)

        const ingresosBrutosListHard = [
            {
                codigo: 0,
                descripcion: 'No inscripto'
            },
            {
                codigo: 1,
                descripcion: 'Inscripto'
            },
            {
                codigo: 2,
                descripcion: 'Exento'
            },
        ]
        setIngresosBrutosList(ingresosBrutosListHard)

        const situacionIVAListHard = [
            {
                codigo: 0,
                descripcion: 'No Incripto'
            },
            {
                codigo: 1,
                descripcion: 'Resp.Inscripto'
            },
            {
                codigo: 3,
                descripcion: 'Exento'
            },
            {
                codigo: 4,
                descripcion: 'No responsable'
            },
            {
                codigo: 5,
                descripcion: 'Consumidor Final'
            },
            {
                codigo: 6,
                descripcion: 'Cons.Final Pers.No Física'
            },
            {
                codigo: 7,
                descripcion: 'Adherido al Monotributo'
            },
            {
                codigo: 8,
                descripcion: 'Resp.no categorizado'
            },
            {
                codigo: 9,
                descripcion: 'IVA no alcanzado'
            },
            {
                codigo: 10,
                descripcion: 'Responsable Monotributo'
            },
            {
                codigo: 11,
                descripcion: 'Monotributo Social'
            },
            {
                codigo: 12,
                descripcion: 'Pequeño Contrib. Eventual'
            },
            {
                codigo: 13,
                descripcion: 'Pequeño Contrib.Eventual Socia'
            },
            {
                codigo: 14,
                descripcion: "RI Factura 'M'"
            },
            {
                codigo: 15,
                descripcion: "RI FACTURA 'A' C/CBU Informado"
            },
        ]
        setSituacionIVAList(situacionIVAListHard)
    }

    const loadUserDataForEdit = () => {
        if(userClientData?.documentId) setTipoDeDocumento(userClientData?.documentId)
        if(userClientData?.documentNumber) setNroDeDocumento(userClientData?.documentNumber)

        if(userClientData?.cuil) setCuitOne(userClientData?.cuil?.slice(0, 2))
        if(userClientData?.cuil) setCuitTwo(userClientData?.cuil?.slice(2, -1))
        if(userClientData?.cuil) setCuitThree(userClientData?.cuil?.slice(-1))

        if(userClientData?.sexId) setSexo(userClientData?.sexId)
        if(userClientData?.iva) setSituacionIva(userClientData?.iva)
        if(userClientData?.civilStatus) setEstadoCivil(userClientData?.civilStatus)

        if(userClientData?.phone) setTelefono(userClientData?.phone)

        if(userClientData?.addressStreet) setCalle(userClientData?.addressStreet)
        if(userClientData?.addressStreet) setNro(userClientData?.addressNumber)
        if(userClientData?.addressFloor) setPiso(userClientData?.addressFloor)
        if(userClientData?.dpto) setDepto(userClientData?.dpto)

        if(userClientData?.rawIncome) setIngresosBrutos(userClientData?.rawIncome)
    }

    const isDisabled = () => {
        return (
            !tipoDeDocumento || nroDeDocumento?.length !== 8 || !sexo || 
            telefono === '' ||
            !situacionIva || !estadoCivil ||
            calle === '' || nro === '' ||
            !ingresosBrutos
        )
    }

    //MODALS
    const CancelAlert = () => {
        return (
            <Modal
                isOpen={cancelAlert}
                onRequestClose={() => setCancelAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <CancelModal
                    cancel={() => setCancelAlert(false)}
                    aceptar={() => {
                        setCancelAlert(false)
                        props.setEnabledEdit(false)
                    }}
                />
            </Modal>
        )
    }

    //MAIN RENDER
    return (
        <div className='userData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <>
                    <div className='userData-container-data'>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='FECHA DE CREACIÓN'
                                value={Moment(userClientData?.createdAt).format('DD/MM/YYYY')}
                                disabled
                            />
                            <CustomTextInput
                                label='ID'
                                value={userClientData?.id}
                                disabled
                            />
                            <div className='userData-container-data-section-status'>
                                <p className='userData-container-data-section-status-label bold medium'>PERFIL</p>
                                <p 
                                    className='userData-container-data-section-status-data regular small'
                                    style={{
                                        backgroundColor: userClientData?.profileCompleted ? 'var(--accent300)' : 'var(--accent500)'
                                    }}
                                >
                                    {userClientData?.profileCompleted ? 'COMPLETO' : 'INCOMPLETO'}
                                </p>
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos personales</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='APELLIDO'
                                value={userClientData?.lastName}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='NOMBRE'
                                value={userClientData?.name}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='NOMBRE COMPLETO'
                                value={userClientData?.lastName + ', ' + userClientData?.name}
                                disabled
                                required
                            />
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>TIPO DE DOCUMENTO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='TIPO DE DOCUMENTO'
                                    placeholder='Seleccioná el tipo de documento'
                                    label='TIPO DE DOCUMENTO'
                                    sortList={tipoDocuList}
                                    itemSelected={(a: any) => setTipoDeDocumento(a?.codigo)}
                                    value={props.enableEdit ? tipoDocuList?.filter((a: any) => a.codigo === tipoDeDocumento)[0]?.descripcion : userClientData?.cuil ? 'CUIT' : tipoDocuList?.filter((a: any) => a.codigo === userClientData?.documentId)[0]?.descripcion}
                                />
                            </div>
                            {userClientData?.cuil ?
                                <div className='userData-container-data-section-customInput'>
                                    <div className='userData-container-data-section-customInput-label'>
                                        <b className='userData-container-data-section-customInput-label-text bold medium'>CUIT</b>
                                        <b className='userData-container-data-section-customInput-label-required bold medium'>&nbsp;*</b>
                                    </div>
                                    <div className='userData-container-data-section-customInput-inputs'>
                                        <input
                                            className='userData-container-data-section-customInput-inputs-input regular medium'
                                            style={{
                                                width: '20%',
                                                backgroundColor: 'transparent'
                                            }}
                                            value={props.enableEdit ? cuitOne : userClientData?.cuil?.slice(0, 2)}
                                            onChange={(e: any) => setCuitOne(e.target.value.replace(/[^0-9]+/g, ''))}
                                            maxLength={2}
                                            disabled
                                        />
                                        -
                                        <input
                                            className='userData-container-data-section-customInput-inputs-input regular medium'
                                            style={{ backgroundColor: 'transparent' }}
                                            value={props.enableEdit ? cuitTwo : userClientData?.cuil?.slice(2, -1)}
                                            onChange={(e: any) => setCuitTwo(e.target.value.replace(/[^0-9]+/g, ''))}
                                            maxLength={8}
                                            disabled
                                        />
                                        -
                                        <input
                                            className='userData-container-data-section-customInput-inputs-input regular medium'
                                            style={{
                                                width: '20%',
                                                backgroundColor: 'transparent'
                                            }}
                                            value={props.enableEdit ? cuitThree : userClientData?.cuil?.slice(-1)}
                                            onChange={(e: any) => setCuitThree(e.target.value.replace(/[^0-9]+/g, ''))}
                                            maxLength={1}
                                            disabled
                                        />
                                    </div>
                                </div>
                                :
                                <CustomTextInput
                                    label='NRO DE DOCUMENTO'
                                    value={props.enableEdit ? nroDeDocumento : userClientData?.documentNumber}
                                    disabled
                                    required
                                    onChange={(e: any) => setNroDeDocumento(e.target.value.replace(/[^0-9]+/g, ''))}
                                    maxLength={8}
                                    error={props.enableEdit ? nroDeDocumento.length !== 8 : userClientData?.documentNumber?.length !== 8}
                                    success={props.enableEdit ? nroDeDocumento.length === 8 : false}
                                />
                            }
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>SEXO</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='SEXO'
                                    placeholder='Seleccioná el sexo'
                                    label='SEXO'
                                    sortList={sexoList}
                                    itemSelected={(a: any) => setSexo(a?.codigo)}
                                    value={props.enableEdit ? sexoList?.filter((a: any) => a.codigo === sexo)[0]?.descripcion : sexoList?.filter((a: any) => a.codigo === userClientData?.sexId)[0]?.descripcion}
                                />
                            </div>
                            <CustomTextInput
                                label='FECHA DE NACIMIENTO'
                                value={Moment(userClientData?.birthDate).format('DD/MM/YYYY')}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='TIPO DE TELÉFONO'
                                value='Particular'
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='TELÉFONO'
                                value={props.enableEdit ? telefono : userClientData?.phone}
                                disabled={!props.enableEdit}
                                required
                                onChange={(e: any) => setTelefono(e.target.value.replace(/[^0-9]+/g, ''))}
                                error={props.enableEdit ? telefono === '' : userClientData?.phone === ''}
                                success={props.enableEdit ? telefono !== '' : false}
                            />
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>SITUACIÓN ANTE EL IVA</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='SITUACIÓN ANTE EL IVA'
                                    placeholder='Seleccioná la Situación ante el IVA'
                                    label='SITUACIÓN ANTE EL IVA'
                                    sortList={situacionIVAList}
                                    itemSelected={(a: any) => setSituacionIva(a?.codigo?.toString())}
                                    value={props.enableEdit ? situacionIVAList?.filter((a: any) => a?.codigo?.toString() === situacionIva)[0]?.descripcion : situacionIVAList?.filter((a: any) => a?.codigo?.toString() === userClientData?.iva)[0]?.descripcion}
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>ESTADO CIVIL</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='ESTADO CIVIL'
                                    placeholder='Seleccioná el estado civil'
                                    label='ESTADO CIVIL'
                                    sortList={estadoCivilList}
                                    itemSelected={(a: any) => setEstadoCivil(a?.codigo)}
                                    value={props.enableEdit ? estadoCivilList?.filter((a: any) => a.codigo === estadoCivil)[0]?.descripcion : estadoCivilList?.filter((a: any) => a.codigo === userClientData?.civilStatus)[0]?.descripcion}
                                />
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos de domicilio</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='TIPO DE DOMICILIO'
                                value='Particular'
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='CALLE'
                                value={props.enableEdit ? calle : userClientData?.addressStreet}
                                disabled
                                required
                                onChange={(e: any) => setCalle(e.target.value)}
                                error={props.enableEdit ? calle === '' : userClientData?.addressStreet === ''}
                                success={props.enableEdit ? calle !== '' : false}
                            />
                            <div className='userData-container-data-section-triple'>
                                <CustomTextInput
                                    label='NRO'
                                    value={props.enableEdit ? nro : userClientData?.addressNumber}
                                    disabled
                                    required
                                    onChange={(e: any) => setNro(e.target.value.replace(/[^0-9]+/g, ''))}
                                    error={props.enableEdit ? nro === '' : userClientData?.addressNumber === ''}
                                    success={props.enableEdit ? nro !== '' : false}
                                />
                                <CustomTextInput
                                    label='PISO'
                                    value={props.enableEdit ? piso : userClientData?.addressFloor}
                                    disabled
                                    placeholder='Opcional'
                                    onChange={(e: any) => setPiso(e.target.value.replace(/[^0-9]+/g, ''))}
                                />
                                <CustomTextInput
                                    label='DEPTO.'
                                    value={props.enableEdit ? depto : userClientData?.dpto}
                                    disabled
                                    placeholder='Opcional'
                                    onChange={(e: any) => setDepto(e.target.value)}
                                />
                            </div>
                            <CustomTextInput
                                label='PROVINCIA'
                                value={userClientData?.provincia}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='LOCALIDAD'
                                value={userClientData?.localidad}
                                disabled
                                required
                            />
                            <CustomTextInput
                                label='CÓDIGO POSTAL'
                                value={userClientData?.addressPostalCode}
                                disabled
                                required
                            />
                        </div>
                        <h5 className='userData-container-data-title bold'>Actividad</h5>
                        <div className='userData-container-data-section'>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>ACTIVIDAD</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='ACTIVIDAD'
                                    placeholder='Seleccioná la Actividad'
                                    label='ACTIVIDAD'
                                    sortList={[]}
                                    value='EMPLEADO'
                                />
                            </div>
                            <div className='userData-container-data-section-selector'>
                                <div className='userData-container-data-section-selector-label'>
                                    <b className='userData-container-data-section-selector-label-text bold medium'>INGRESOS BRUTOS</b>
                                    <b className='userData-container-data-section-selector-label-required bold medium'>&nbsp;*</b>
                                </div>
                                <SelectorInput
                                    disabled
                                    id='INGRESOS BRUTOS'
                                    placeholder='Seleccioná la Actividad'
                                    label='INGRESOS BRUTOS'
                                    sortList={ingresosBrutosList}
                                    itemSelected={(a: any) => setIngresosBrutos(a?.codigo?.toString())}
                                    value={props.enableEdit ? ingresosBrutosList?.filter((a: any) => a?.codigo?.toString() === ingresosBrutos)[0]?.descripcion : ingresosBrutosList?.filter((a: any) => a?.codigo?.toString() === userClientData?.rawIncome)[0]?.descripcion}
                                />
                            </div>
                        </div>
                        <h5 className='userData-container-data-title bold'>Datos de cuenta</h5>
                        <div className='userData-container-data-section'>
                            <CustomTextInput
                                label='EMAIL'
                                value={userClientData?.username}
                                disabled
                                required
                            />
                        </div>
                    </div>
                    {props.enableEdit &&
                        <div className='userData-container-editable'>
                            <div className='userData-container-editable-editing'>
                                <MainButton
                                    text='CANCELAR'
                                    inverted
                                    onPress={() => setCancelAlert(true)}
                                />
                                <MainButton
                                    text='GUARDAR'
                                    disabled={isDisabled()}
                                    onPress={() => {
                                        props.setEnabledEdit(false)
                                        const data = { 
                                            "phone": telefono
                                        }
                                        actionEditUser(data, userClientData?.id, setLoading, props.setNotification)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </>
            }
            {CancelAlert()}
        </div>
    );

}

export default UserData;