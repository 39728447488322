import React from 'react'
import './GraphMetrics.css'

const GraphMetrics = (props: any) => {

    return (
        <div className='graphMetrics-container' style={{ backgroundColor: props.switchColors ? 'var(--primary200)' : 'var(--white)' }}>
            <div className='graphMetrics-container-data'>
                <h4 className='graphMetrics-container-data-value bold'>{props.value}</h4>
                <p className='graphMetrics-container-data-label regular small'>{props.label}</p>
            </div>
            {props.icon === 'chart_svg1' ?
                <svg width="207" height="60" viewBox="0 0 207 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 44.461H67L73 58.6501L83 28.1501L90.5 51.1501L101 1.34985L112.5 31.6231L120.5 20.5002L143.5 56.1727L158 38.5002L171.083 56.1727L188 31.6231H207" stroke={props.switchColors ? "var(--white)" : "var(--primary200)"} stroke-width="2.5" stroke-linejoin="round"/>
                </svg>
                : props.icon === 'chart_svg2' ?
                <svg width="189" height="54" viewBox="0 0 189 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 22.2703H11L39.7393 37.8108L57.4414 22.2703L64.1117 32.1802L77.709 7.63063C81.4717 20.2432 89.0999 46.2793 89.5103 49.5225L113.113 4.7027L133.894 52L142.103 32.1802L151.083 49.5225L177.508 2L187 24.973" stroke={props.switchColors ? "var(--white)" : "var(--primary200)"} stroke-width="2.5" stroke-linejoin="round"/>
                </svg>
                :
                <svg width="209" height="54" viewBox="0 0 209 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 37.8108H59.7393L77.4414 22.2703L84.1117 32.1802L97.709 7.63063C101.472 20.2432 109.1 46.2793 109.51 49.5225L133.113 4.7027L153.894 52L162.103 32.1802L171.083 49.5225L197.508 2L207 24.973" stroke={props.switchColors ? "var(--white)" : "var(--primary200)"} stroke-width="2.5" stroke-linejoin="round"/>
                </svg>
            }
        </div>
    );

}

export default GraphMetrics;