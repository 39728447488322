import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './CrearUser.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';

const CrearUser = (props: any) => {

    return (
        <div className='crearUser-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='crearUser-container-main'>
                <div className='crearUser-container-main-hedear'>
                    <div className='crearUser-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <div className='crearUser-container-main-main'>
                    <p className='crearUser-container-main-main-title'>Crear Usuario</p>
                </div>
            </div>
        </div>
    );
}

export default CrearUser;