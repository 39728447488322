import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Users.css'
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import TableHeader from '../../components/TableHeader/TableHeader';

const Users = (props: any) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='users-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='users-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'users:download' }).length > 0}
                    data={{
                        type: 'users',
                        search: 'v2/user',
                        createTitle: 'CREAR USUARIO',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        createPath: '/users/create'
                    }}
                    idType='ID'
                    goToDetails={(id: any) => navigate('/users/' + id + '#Detalle%20de%20usuario')}
                    canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'users:create' }).length > 0}
                />
            </div>
        </div>
    );
}

export default Users;