import React, { useState } from "react";
import "./GlobalDownload.css";
import txtImage from '../../../../assets/images/svgs/txt.svg'
import { actionsDownloadTxt } from "../../../../actions";
import Moment from 'moment/moment'
import { ReactSVG } from 'react-svg'

const GlobalDownload = (props: any) => {

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <React.Fragment>
      {loading ? 
        <div className="globalDownload-loader">
          <span className="globalDownload-loader-loader"></span>
        </div>
        :
        <div className="globalDownload">
          <div className="globalDownload-img">
            <ReactSVG 
              src={txtImage}
              onClick={() => {
                const dataSend = {
                  'ids': props.selectedList
                }
                actionsDownloadTxt(dataSend, Moment(new Date()).format('DDMMYYYY'), setLoading)
              }}
            />
          </div>
          <span className="tooltip">{props.tooltip}</span>
        </div>
      }
    </React.Fragment>
  )
};

export default GlobalDownload;
