import React, {useState} from 'react'
import { 
    actionLoginProcessId
} from '../../../actions'
import MainButton from '../../../components/MainButton/MainButton'
import '../Login.css'
import CustomTextInput from '../../../components/CustomTextInput/CustomTextInput';
import LeftArrow from '../../../assets/images/svgs/leftArrow.svg';
import { ReactSVG } from 'react-svg'
import { useNavigate } from "react-router-dom";
import { sonLogo, sonLogoSize } from '../../../data';
import emptyProfile from '../../../assets/images/EmptyProfile.png';
import { FormattedMessage, useIntl } from 'react-intl';
const PasswordStep = (props) => {

    const [passwordText, setPasswordText] = useState('')
    const [localLoading, setLocalLoading] = useState(false)
    const navigate = useNavigate();

    const handleLoginProcess = () => {
        setLocalLoading(true)
        const data = {
            "step": "password",
            "password": passwordText
        }
        actionLoginProcessId(data, props.loginProcessData?.id, props.setLoginProcessData, props.rememberAccount, navigate, setLocalLoading)
    }

    const buttonDisabled = () => {
        return passwordText === ''
    }

    return (
        <div className='login-container-main'>
            <img style={{ width: sonLogoSize }} src={sonLogo} />
            <p className='login-container-main-title'>{props.loginProcessData?.config.name}, <FormattedMessage id="admin.password.title" defaultMessage="" /></p>
            <p className='login-container-main-subTitle'><FormattedMessage id="admin.password.subTitle" defaultMessage="" /></p>
            <div className='login-container-main-profileContainer'>
                <img className='login-container-main-profileContainer-img' src={props.loginProcessData?.config?.imageProfile ? props.loginProcessData?.config?.imageProfile : emptyProfile} />
            </div>
            <div className='login-container-main-emailContainer'>
                <ReactSVG 
                    src={LeftArrow}
                    onClick={() => props.setLoginProcessData({step: 'user'})}
                    style={{ cursor: 'pointer' }}
                />
                <p className='login-container-main-emailContainer-text'>{props.loginProcessData?.config.user_id}</p>
            </div>
            <div className='login-container-main-inputWrapper'>
                <CustomTextInput
                    label='Contraseña'
                    onChange={(e) => setPasswordText(e.target.value)}
                    value={passwordText}
                    ispassword="true"
                    error={props.loginProcessData?.config?.message}
                    errorMessage={props.loginProcessData?.config?.message}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !buttonDisabled()) {
                            handleLoginProcess()
                        }
                    }}
                />
            </div>
            <div className='login-container-main-OlvidasteEmailButtonWrapper'>
                <MainButton
                    id='OlvidasteContraseña'
                    text={useIntl().formatMessage({id:"admin.password.olvidar"})}
                    onPress={() => navigate('/recovery', { state: { userName: props.loginProcessData?.config.user_id } })}
                    sinFondo
                />
            </div>
            <div className='login-container-main-buttonWrapper'>
                <MainButton
                    id='Login'
                    disabled={buttonDisabled()}
                    text={localLoading ? 'Verificando...' : 'INICIAR SESIÓN'}
                    onPress={() => handleLoginProcess()}
                />
            </div>
        </div>
    );

}

export default PasswordStep;