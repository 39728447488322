import React, { useState } from 'react'
import './AlertComponent.css'
import {ReactComponent as Alert} from '../../../assets/images/svgs/alertIcon.svg';

const AlertComponent = (props: any) => {

    const [data] = useState<any>(JSON.parse(props.data))

    return (
        <div className='alertComponent-container'>
            <b className='alertComponent-container-text regular medium' style={{color: props.hovered ? 'var(--primary500)' : 'var(--gray700)'}}>{data?.name}</b>
            {data?.date && 
                <div className="alertComponent-container-tooltip">
                    <Alert style={{ marginLeft: 10 }}/>
                    {data?.alert && <span className="alertComponent-container-tooltiptext">{data?.alert}</span>}
                </div>
            }
        </div>
    );
}

export default AlertComponent;