import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './ContactForm.css'
import HedearIcons from '../../components/HedearIcons/HedearIcons';
import UserAdminProfile from '../../components/UserAdminProfile/UserAdminProfile';
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation } from 'react-router-dom';

const ContactForm = (props: any) => {

    const location = useLocation();

    return (
        <div className='contactForm-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='contactForm-container-main'>
                <div className='contactForm-container-main-hedear'>
                    <div className='contactForm-container-main-hedear-iconWrapper'>
                        <HedearIcons countryId={props.userData?.countryId} />
                    </div>
                    <UserAdminProfile
                        optionList={props.userList}
                        userData={props.userData}
                        mini
                    />
                </div>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'contacts:download' }).length > 0}
                    data={{
                        type: 'contact_forms',
                        createPath: '/contact_forms/create',
                        search: 'contact_forms',
                        createTitle: 'CREAR Contactos',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        subTitle: 'Mensajes que llegan desde la sección “Contactanos” y solicitudes de llamado desde el sitio web.'
                    }} canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'contacts:create' }).length > 0}
                    emptyText='Actualmente no tienes mensajes.'
                />
            </div>
        </div>
    );
}

export default ContactForm;