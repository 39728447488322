import React from 'react'
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Archivos.css'
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import TableHeader from '../../components/TableHeader/TableHeader';

const Archivos = (props: any) => {

    const location = useLocation();

    return (
        <div className='archivos-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='archivos-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'files:download' }).length > 0}
                    data={{
                        type: 'files',
                        search: 'v2/files',
                        subTitle: 'Espacio de almacenamiento de datos para ejecutar acciones masivas y acceder al historial',
                        createTitle: 'CREAR COTIZACIÓN',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                    }}
                    idType='ID'
                    setGlobalLoading={props.setGlobalLoading}
                    canUpload={props.userData?.privileges?.filter(function(e: any) { return e === 'files:upload' }).length > 0}
                />
            </div>
        </div>
    );
}

export default Archivos;