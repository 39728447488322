import React, {useState} from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { sonLogo } from '../../data';
import './Responsive.css'

const Responsive = () => {

    return (
        <div className='responsive-container'>
            <img className='responsive-container-logo' src={sonLogo} />
            <p className='responsive-container-title'><FormattedMessage id="admin.mobile.title" defaultMessage="" /></p>
            <p className='responsive-container-subTitle' dangerouslySetInnerHTML={{ __html: useIntl().formatMessage({id:"admin.mobile.subTitle"}) }}></p>
        </div>
    );

}

export default Responsive;