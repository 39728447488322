import React from 'react'
import './EmptyList.css'

const EmptyList = (props: any) => {

    return (
        <div className='emptyList-container'>
            {props.svg}
            <p className='emptyList-container-text'>{props.text}</p>
        </div>
    );

}

export default EmptyList;