import React, { useState } from "react";
import "./DateBetweenOptionListComponent.css";
import DateBetweenComponent from "../DateBetweenComponent/DateBetweenComponent";

const DateBetweenOptionListComponent = (props: any) => {

  const [selectedOption, setSelectedOption] = useState<any>(null); 

  return (
    <div className="dateBetweenOptionListComponent-container">
      <div className="dateBetweenOptionListComponent-container-list">
        {props.data?.list?.map((option: any, index: any) => (
          <div key={index} className="dateBetweenOptionListComponent-container-list-item">
            <div 
              className="dateBetweenOptionListComponent-container-list-item-circle"
              style={{ cursor: selectedOption?.title === option?.title ? 'auto' : 'pointer' }}
              onClick={() => selectedOption?.title !== option?.title ? setSelectedOption(option) : {}}
            >
              <div 
                className="dateBetweenOptionListComponent-container-list-item-circle-inner"
                style={{transform: selectedOption?.title === option?.title ? "scale(1)" : "scale(0)" }}
              />
            </div>
            <b className="dateBetweenOptionListComponent-container-list-item-text regular medium">{option?.title}</b>
          </div>
        ))}
      </div>
      <div className="dateBetweenOptionListComponent-container-selectedOptionList">
        {selectedOption?.elements?.map((element: any, index: any) => (
          <DateBetweenComponent
            key={index}
            changeValue={(value: any, opcionText: any) => props.changeValue(value, opcionText)}
            data={element}
          />
        ))}
      </div>
    </div>
  );
};

export default DateBetweenOptionListComponent;
