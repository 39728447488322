import React, { useEffect, useRef, useState } from 'react'
import './DetailAccions.css'

const DetailAccions = (props: any) => {

    const [openList, setOpenList] = useState<boolean>(false);

    const listRef: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (listRef.current && !listRef.current.contains(event.target)) {
                setOpenList(false) 
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [listRef]);

    return (
        <div className='detailAccions-container'>
            {props.loadingDownload ?
                <div className='detailAccions-container-load'>
                    <span className='detailAccions-container-load-loader'/>
                </div>
                :
                <>
                    <div 
                        className='detailAccions-container-main'
                        onClick={() => setOpenList(!openList)}
                    >
                        <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 28}}>more_vert</span>
                    </div>
                    {openList && 
                        <div className='detailAccions-container-list' ref={listRef}>
                            {props.accionList?.map((a: any, index: any) => (
                                <div 
                                    key={index}
                                    className='detailAccions-container-list-item'
                                    onClick={() => {
                                        setOpenList(false)
                                        a.onClick()
                                    }}
                                    style={{display: a.show ? 'flex' : 'none'}}
                                >
                                    <b className='detailAccions-container-list-item-text regular medium'>{a.name}</b>
                                </div>
                            ))}
                        </div>
                    }
                </>
            }
        </div>
    );
}

export default DetailAccions;