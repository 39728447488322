import React, { useEffect, useState } from 'react'
import './DeleteComponent.css'

const DeleteComponent = (props: any) => {

    const [data, setData] = useState<any>(null)

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    useEffect(() => {
        if(props.data) {
            handleParse();
        }
    }, [props.data])

    return (
        <div className='deleteComponent-text'>
            <span className="material-symbols-rounded">{data?.icon}</span>
        </div>
    );
}

export default DeleteComponent;