import React, { useEffect, useState } from 'react'
import './GrouperIntereses.css'
import Lottie from 'react-lottie';
import sentSuccess from '../../../assets/lotties/cardSkeleton.json'
import GraphMetrics from '../GraphMetrics/GraphMetrics';
import TopThreeMetrics from '../TopThreeMetrics/TopThreeMetrics';

const GrouperIntereses = (props: any) => {
    
    useEffect(() => {
    }, [props.data])

    if(props.loading) {
        return (
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: sentSuccess,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                }}
                width={223}
                isStopped={false}
                isPaused={false}
                style={{margin: 0}}
            />
        )
    }

    const getMethod = (text: any) => {
        if (text.includes("TOP 3")) return "TOP 3"
        else return text
    }

    const groupedItemsDashboardMethods: any = {
        "Cotizaciones": {
            name: "groupedItemsDashboardMethods.cotizaciones",
            methodComponent: GraphMetrics,
        },
        "Accesos a los planes de salud": {
            name: "groupedItemsDashboardMethods.cotizaciones",
            methodComponent: GraphMetrics,
        },
        "TOP 3": {
            name: "groupedItemsDashboardMethods.TOP 3",
            methodComponent: TopThreeMetrics,
        }
    }

    const CardComponent: any = groupedItemsDashboardMethods[getMethod(props.data?.label)].methodComponent;

    return (
        <div className='grouperIntereses-container'>
            <CardComponent
                value={props.data?.value}
                label={props.data?.label}
                list={props.data?.value}
                title={props.data?.label.replace(/^TOP 3 - /, "")}
                switchColors
                alert={props.data?.alert}
                icon={props.data?.icon}
            />
        </div>
    );

}

export default GrouperIntereses;