import React, { useEffect, useState } from 'react'
import { 
    getPolizaData,
    getCheckoutData,
    actionGetCobranzaData
} from '../../../actions'
import CustomTextInput from '../../CustomTextInput/CustomTextInput'
import './PolizasData.css'
import Moment from 'moment/moment'
import loadingLottie from '../../../assets/lotties/loading.json'
import Lottie from 'react-lottie';
import { estadoCoberturaList } from '../../../util/EstadoCoberturaListColors'
import { estadoPagoList } from '../../../util/EstadoPagoListColors'

const PolizasData = (props: any) => {

    const [polizaData, setPolizaData] = useState<any>(null)
    const [checkoutData, setCheckoutData] = useState<any>(null)
    const [packageData, setPackageData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingFalse, setLoadingFalse] = useState<boolean>(false)
    const [cobranzaData, setCobranzaData] = useState<any>([])

    useEffect(() => {
        getPolizaData(props.polizaId, setPolizaData, props.from, setLoading)
        actionGetCobranzaData(props.polizaId, setCobranzaData, setLoadingFalse);
    }, [])

    useEffect(() => {
        if(polizaData) {
            getCheckoutData(polizaData?.checkoutId, setCheckoutData, setLoading)
            props.setPolicyNumber(polizaData?.number)
            props.setPolicyUrl(polizaData?.url)
        }
    }, [polizaData])

    useEffect(() => {
        if(checkoutData) {
            setPackageData(checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0])
            props.setCheckoutData(checkoutData)
        }
    }, [checkoutData])

    useEffect(() => {
        if (packageData) props.setPolicyInsurer(packageData?.insurer?.id)
    }, [packageData])
    
    const getFormaDePago = (type: string) => {
        switch(type) {
            case 'realcash':
                return 'Efectivo';
            case 'simple_creditcard':
                return 'Tarjeta de crédito';
            case 'cbu':
                return 'CBU';
            case 'automatic_debit':
                return 'Débito automático';
            default:
                return 'Efectivo';
        }
    }

    const capitalizeFirstLetter = (str: any) => {
        if (str?.length === 0) return str;
        return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    }

    const generateFechaAnulacion = (checkoutStatus: any, latestPayment: any, insuranceName: any) => {
        let dateAnulacion = '';
        if (checkoutStatus === 'CANCELED' || checkoutStatus === 'CANCEL_REQUESTED_ADMIN') {
            dateAnulacion = Moment(latestPayment).format('DD-MM-YYYY');
        } else {
            if (insuranceName === 'Mercantil Andina') {
                dateAnulacion = Moment(latestPayment).add(2, 'months').format('DD-MM-YYYY');
            } else if (insuranceName === 'San Cristóbal') {
                dateAnulacion = Moment(latestPayment).add(17, 'days').format('DD-MM-YYYY');
            }
        }
        return dateAnulacion;
    }

    return (
        <div className='polizasData-container'>
            {loading ? 
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loadingLottie,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                    width={200}
                    isStopped={false}
                    isPaused={false}
                    style={{margin: 'auto'}}
                />
                :
                <div className='polizasData-container-data'>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='PÓLIZA'
                            value={polizaData?.number}
                            disabled
                        />
                        <CustomTextInput
                            label='ESTADO'
                            value={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.title}
                            disabled
                            color={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                            tooltipIcon={checkoutData?.status === 'FAILED_EMISSION' ? 'El motivo es: ' + checkoutData?.config?.beautifyError : null}
                        />
                        <CustomTextInput
                            label='NRO DE COBERTURA'
                            value={props.polizaId}
                            disabled
                            color={estadoCoberturaList?.filter((a: any) => (a.status == checkoutData?.status))[0]?.color}
                        />
                        <CustomTextInput
                            label='ASEGURA'
                            value={polizaData?.productPolicyDTO?.insurerName}
                            disabled
                        />
                        <CustomTextInput
                            label='INICIO DE VIGENCIA'
                            value={Moment(polizaData?.fechaDesde).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='FIN DE VIGENCIA'
                            value={Moment(polizaData?.fechaDesde).add('month', 1).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='FIN DE PÓLIZA'
                            value={Moment(polizaData?.fechaHasta).format('DD/MM/YYYY')}
                            disabled
                        />
                        <CustomTextInput
                            label='VIGENCIA'
                            value='Anual'
                            disabled
                        />
                        <CustomTextInput
                            label='VALOR'
                            value={cobranzaData?.length === 0 ? packageData?.price?.formattedValue : cobranzaData[cobranzaData?.length - 1]?.value}
                            disabled
                            underText={'PRÓX CUOTA ' + Moment(polizaData?.fechaDesde).add(1, 'M').format('DD/MM')}
                            underColor='var(--warningPrimary)'
                        />
                        {polizaData?.status?.paymentStatus && <CustomTextInput
                            label='ESTADO DE PAGO'
                            disabled
                            value={estadoPagoList?.filter((a: any) => (a.title?.toLowerCase() == polizaData?.status?.paymentStatus?.toLowerCase()))[0]?.title}
                            color={estadoPagoList?.filter((a: any) => (a.title?.toLowerCase() == polizaData?.status?.paymentStatus?.toLowerCase()))[0]?.color}
                        />}
                        {polizaData?.status?.paymentExpiration && <CustomTextInput
                            label='VTO. ASEG.'
                            disabled
                            value={Moment(polizaData?.status?.paymentExpiration).format('DD-MM-YYYY')}
                        />}
                        {polizaData?.status?.paymentExpiration && <CustomTextInput
                            label='ANULACIÓN'
                            disabled
                            value={generateFechaAnulacion(checkoutData?.status, polizaData?.status?.paymentExpiration, polizaData?.productPolicyDTO?.insurerName)}
                        />}
                        <CustomTextInput
                            label='FORMA DE PAGO'
                            value={getFormaDePago(checkoutData?.paymentInfo?.paymentType)}
                            disabled
                        />
                    </div>
                    <h5 className='polizasData-container-data-title bold'>Más datos</h5>
                    <div className='polizasData-container-data-section'>
                        <CustomTextInput
                            label='PLAN'
                            value={packageData?.description}
                            disabled
                        />
                        {packageData?.coverageGroups[0]?.coverages?.map((a: any, index: any) => (
                            <CustomTextInput
                                key={index}
                                label={a.title?.toUpperCase()}
                                value={a.formattedValue}
                                disabled
                            />
                        ))}
                        {polizaData?.status?.paymentEndoso && <CustomTextInput
                            label='TIPO DE ENDOSO'
                            disabled
                            value={capitalizeFirstLetter(polizaData?.status?.paymentEndoso)}
                        />}
                    </div>
                </div>
            }
        </div>
    );

}

export default PolizasData;