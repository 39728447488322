import React from "react";
import "./ChipComponent.css";

interface ChipProps {
  text: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ChipComponent: React.FC<ChipProps> = ({ text, onClose }) => {
  return (
    <div className="chip">
      <span onClick={onClose}>{text} </span>
      <span
        className="material-symbols-rounded bold medium"
        style={{ cursor: "pointer", marginTop:'3px' }}
        onClick={onClose}
      >
        close
      </span>
    </div>
  );
};

export default ChipComponent;
