import { saveAs } from 'file-saver';
import Moment from 'moment/moment'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoMA from '../assets/images/Logo-mercantil-aux.png';
import footerImage from '../assets/images/MaCertificadoFooter.png';

export const createPdf = (userData: any, checkoutData: any, policyData: any, quoteData: any) => {
    
    const Asegurado = userData?.name + ', ' + userData?.lastName;
    var Domicilio;
    Domicilio = userData?.addressStreet + ' ' + userData?.addressFloor + ' ' + userData?.localidad + ' (' + userData?.addressPostalCode + ') ' + userData?.provincia;
    if(userData?.addressNumber) Domicilio = userData?.addressStreet + ' ' + userData?.addressNumber + ' ' + userData?.addressFloor + ' ' + userData?.localidad + ' (' + userData?.addressPostalCode + ') ' + userData?.provincia;
        

    const VigenciaDesde = Moment(policyData?.fechaDesde).format('DD.MM.YYYY');
    const VigenciaHasta = Moment(policyData?.fechaDesde).add(1, 'months').format('DD.MM.YYYY');

    const Dominio = checkoutData?.productInfo?.carplate[0];
    const MARCAMODELOVERSION = quoteData?.previousQuestions?.filter((a: any) => a.questionId === "33_car_version")[0]?.label?.toUpperCase();
    const Año = quoteData?.previousQuestions?.filter((a: any) => a.questionId === "32_car_year")[0]?.label;
    const Motor = checkoutData?.productInfo?.carmotornumber[0];
    const Chasis = checkoutData?.productInfo?.carchasisnumber[0];

    const Cobertura = checkoutData?.offer?.packages?.filter((a: any) => a?.id === checkoutData?.offer?.currentPackage)[0]?.descriptionFull;

    const Uso = quoteData?.previousQuestions?.filter((a: any) => a.questionId === "35_caruse")[0]?.label;

    const doc: any = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'cm',
        format: 'a4', // 'a3', 'a4', 'a5', etc.
    });

    const imgWidth = 3; // Adjust the image width as needed
    const imgHeight = 1.5; // Adjust the image height as needed
    const imgX = 2; // X position
    const imgY = 2; // Y position

    // Add the converted SVG image to the PDF
    let logo = logoMA;
    doc.addImage(logo, 'PNG', imgX, imgY, imgWidth, imgHeight);

    //Set font style
    doc.setFontSize(11.5);
    doc.setFont('arial', 'bold');

    //Title
    // Calculate the x-coordinate to center the text
    const titleText = 'CERTIFICADO DE COBERTURA';
    const titleWidth = doc.getStringUnitWidth(titleText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
    doc.text(titleText, titleX, 4);

    // Draw underline
    doc.setLineWidth(0.01);
    doc.line(titleX, 4.05, titleX + titleWidth, 4.05);

    // Reset font style to normal
    doc.setFont('calibri', 'normal');

    //Pza.: E/T
    doc.text('Pza.: E/T', 2, 5);

    //Asegurado
    doc.text('Asegurado: ' + Asegurado, 2, 6);
    //Domicilio
    doc.text('Domicilio: ' + Domicilio, 2, 6.5);

    //Vigencia
    // Calculate the x-coordinate to center the text
    const vigenciaText = 'Vigencia: ' + VigenciaDesde + ' al ' + VigenciaHasta;
    const vigenciaWidth = doc.getStringUnitWidth(vigenciaText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const vigenciaX = (doc.internal.pageSize.getWidth() - vigenciaWidth) / 2;
    doc.text(vigenciaText, vigenciaX, 7.5);

    //Dominio
    doc.text('DOMINIO: ' + Dominio, 2, 8.5);
    //Marca
    doc.text('MARCA/MODELO/VERSIÓN: ' + MARCAMODELOVERSION, 2, 9);
    //Año
    doc.text('AÑO: ' + Año, 2, 9.5);
    //Motor
    doc.text('MOTOR: ' + Motor, 2, 10);
    //Chasis
    doc.text('CHASIS: ' + Chasis, 2, 10.5);

    //Cobertura
    doc.text('Cobertura: ' + Cobertura, 2, 11.5);

    //Uso
    doc.text('USO: (' + Uso + ')', 2, 12.5);

    // Initialize with your own date value
    const dateString = Moment(policyData?.fechaDesde).format('YYYY-MM-DD'); // Example date string in 'YYYY-MM-DD' format
    const date = new Date(dateString);
    
    // Get the month name in Spanish
    const monthName = date.toLocaleString('es', { month: 'long' });
    
    //Text
    const mainText = 'SE EXTIENDE EL PRESENTE CERTIFICADO EN LA CIUDAD DE BS. AS. A LOS ' + Moment(policyData?.fechaDesde).date() + ' DIAS ' +
    'DEL MES DE ' + monthName?.toUpperCase() + ' DE ' + Moment(policyData?.fechaDesde).year() + ', A PEDIDO DEL ASEGURADO Y AL SOLO EFECTO DE ' +
    'SER PRESENTADO ANTE QUIEN CORRESPONDA. ' +
    'LA EXTENSION DEL PRESENTE CERTIFICADO NO IMPLICA RENUNCIAR A OPONER LA ' +
    'SUSPENSION O CADUCIDAD DE LA COBERTURA POR FALTA DE PAGO. ' +
    'Los asegurados podrán solicitar información ante la Superintendencia de Seguros de la Nación ' +
    'con relación a la situación económico financiera de la entidad aseguradora, dirigiéndose ' +
    'personalmente o por nota a Julio A. Roca 721 (C.C.1067), Buenos Aires, o a los teléfonos: ' +
    '4343-9090, 4331-0188 o 4331-7456, en el horario de 12:30 a 18:30. Podrá consultarse vía ' +
    'Internet a la siguiente dirección: https://www.ssn.gob.ar/'

    const lines = doc.splitTextToSize(mainText, 17); // Adjust the width as needed

    doc.text(lines, 2, 14); // Add text to the document

    const imgFooterWidth = 10; // Adjust the image width as needed
    const imgFooterHeight = 4; // Adjust the image height as needed
    const imgFooterY = 19; // Y position

    // Calculate X position to align to the right
    const pdfWidth = doc.internal.pageSize.getWidth();
    const imgFooterX = pdfWidth - imgFooterWidth - 1;

    // Add the converted SVG image to the PDF
    let logoFooter = footerImage;
    doc.addImage(logoFooter, 'PNG', imgFooterX, imgFooterY, imgFooterWidth, imgFooterHeight);

    //Set font style
    doc.setFontSize(6.5);

    // Define the text content
    const footerText = "Necochea 183 (5500) Capital, Mendoza t.(54 61) 4298388 f (54 61) 4255998";
    const footerWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const footerX = (doc.internal.pageSize.getWidth() - footerWidth) / 2;
    doc.text(footerText, footerX, doc.internal.pageSize.getHeight() - 1);

    // Define the text content
    const footerText2 = "Av. Belgrano 672 (1106), Capital Federal, Buenos Aires t. 0800-333-1220  t. (54 11) 4310 5400";
    const footerWidth2 = doc.getStringUnitWidth(footerText2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const footerX2 = (doc.internal.pageSize.getWidth() - footerWidth2) / 2;
    doc.text(footerText2, footerX2, doc.internal.pageSize.getHeight() - 0.8);

    // Save the PDF with a name
    doc.save('CERTIFICADO DE COBERTURA.pdf');

}